import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { ContentImagePipe } from './pipes/content-image.pipe';
import { FortressCurrencyPipe } from './pipes/fortress-currency.pipe';
import { ManageTicketsComponent } from './components/manage-tickets/manage-tickets.component';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { CardTypeImageUrlPipe } from './pipes/card-type-image-url.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImgDefaultDirective } from './directives/img-default.directive';
import { InPopulationDirective } from './directives/in-population.directive';
import { NotInPopulationDirective } from './directives/not-in-population.directive';
import { IsClientTypeDirective } from './directives/is-client-type.directive';
import { IsNotClientTypeDirective } from './directives/is-not-client-type.directive';
import { StickyDirective } from './directives/sticky.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GiftCodesComponent } from './components/gift-codes/gift-codes.component';
import { InProductCodeDirective } from './directives/in-product-code.directive';
import { NotInProductCodeDirective } from './directives/not-in-product-code.directive';
import { TmCallbackComponent } from './components/manage-tickets/tm-callback/tm-callback.component';
import { ConfirmModalTermsDirective } from './directives/confirm-modal-terms.directive';
import { ConfirmModalTermsComponent } from './components/confirm-modal-terms/confirm-modal-terms.component';
import { RouterModule } from '@angular/router';
import { HasTagDirective } from './directives/has-tag.directive';
import { NotHasTagDirective } from './directives/not-has-tag.directive';
import { TmAuthCallbackComponent } from './components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { ContentFilePipe } from './pipes/content-file.pipe';
import { FgbYoutubePlayerComponent } from './components/providers/youtube/fgb-youtube-player/fgb-youtube-player.component';
import { FgbVimeoPlayerComponent } from './components/providers/vimeo/fgb-vimeo-player/fgb-vimeo-player.component';
import { FgbSVPPlayerComponent } from './components/providers/streamingVideoProvider/fgb-svp-player/fgb-svp-player.component';
import { FgbTwitchPlayerComponent } from './components/providers/twitch/fgb-twitch-player/fgb-twitch-player.component';
import { FgbZoomConferenceComponent } from './components/providers/zoom/fgb-zoom-conference/fgb-zoom-conference.component';
import { FgbBoxcastPlayerComponent } from './components/providers/boxcast/fgb-boxcast-player/fgb-boxcast-player.component';
import { FgbStreamablePlayerComponent } from './components/providers/streamable/fgb-streamable-player/fgb-streamable-player.component';
import { FgbFacebookPlayerComponent } from './components/providers/facebook/fgb-facebook-player/fgb-facebook-player.component';
import { TimeSinceComponent } from './components/time-since/time-since.component';
import { CartItemComponent } from '../modules/loyalty/components/cart-item/cart-item.component';
import { CartMobileShortcutComponent } from '../modules/loyalty/components/cart-item/cart-mobile-shortcut/cart-mobile-shortcut.component';
import { CartModalComponent } from '../modules/loyalty/components/cart-item/cart-modal/cart-modal.component';
import { CtrlTemplateDirective } from './directives/control-template.directive';
import { CountdownWatchComponent } from './components/countdown-watch/countdown-watch.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SportsAllianceCallbackComponent } from './components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { NumberInputModule } from '@fgb/portal-component-library/src/lib/shared/number-input';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { ProviderCallbackComponent } from './components/providers/provider-callback/provider-callback.component';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { SortBarModule } from '@fgb/portal-component-library/src/lib/shared/sort-bar';
import { KeyCloakCallbackComponent } from './components/providers/key-cloak/key-cloak-callback/key-cloak-callback.component';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { ConfirmModalModule } from '@fgb/portal-component-library/src/lib/shared/confirm-modal';
import { DateSelectModule } from './components/date-select/date-select.module';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';

@NgModule({
  declarations: [
    ContentImagePipe,
    ContentFilePipe,
    FortressCurrencyPipe,
    CreditCardNumberPipe,
    CardTypeImageUrlPipe,
    SafeHtmlPipe,
    WithLoadingPipe,
    ManageTicketsComponent,
    ImgDefaultDirective,
    InPopulationDirective,
    NotInPopulationDirective,
    IsNotClientTypeDirective,
    IsClientTypeDirective,
    StickyDirective,
    GiftCodesComponent,
    InProductCodeDirective,
    NotInProductCodeDirective,
    TmCallbackComponent,
    ConfirmModalTermsDirective,
    ConfirmModalTermsComponent,
    HasTagDirective,
    NotHasTagDirective,
    TmAuthCallbackComponent,
    FgbYoutubePlayerComponent,
    FgbVimeoPlayerComponent,
    FgbSVPPlayerComponent,
    FgbTwitchPlayerComponent,
    FgbZoomConferenceComponent,
    FgbBoxcastPlayerComponent,
    FgbStreamablePlayerComponent,
    TimeSinceComponent,
    FgbFacebookPlayerComponent,
    CartItemComponent,
    CartModalComponent,
    CartMobileShortcutComponent,

    CtrlTemplateDirective,
    CountdownWatchComponent,
    SportsAllianceCallbackComponent,
    KeyCloakCallbackComponent,
    ProviderCallbackComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbModule,
    RouterModule,
    InlineSVGModule,
    GoogleMapsModule,
    ErrorsModule,
    NumberInputModule,
    SortBarModule,
    CountdownModule,
    LoyaltyIconModule,
    LoadingModule,
    DirectivesModule,
    PipesModule,
    CountryAndRegionsModule,
    ConfirmModalModule,
    FGBSharedModule
  ],
  exports: [
    ContentImagePipe,
    ContentFilePipe,
    FortressCurrencyPipe,
    CreditCardNumberPipe,
    CardTypeImageUrlPipe,
    SafeHtmlPipe,
    WithLoadingPipe,
    LoadingModule,
    ManageTicketsComponent,
    ImgDefaultDirective,
    InPopulationDirective,
    NotInPopulationDirective,
    IsNotClientTypeDirective,
    IsClientTypeDirective,
    StickyDirective,
    GiftCodesComponent,
    InProductCodeDirective,
    NotInProductCodeDirective,
    ConfirmModalTermsDirective,
    HasTagDirective,
    NotHasTagDirective,
    TimeSinceComponent,
    FgbYoutubePlayerComponent,
    FgbVimeoPlayerComponent,
    FgbSVPPlayerComponent,
    FgbTwitchPlayerComponent,
    FgbZoomConferenceComponent,
    FgbBoxcastPlayerComponent,
    FgbStreamablePlayerComponent,
    FgbFacebookPlayerComponent,
    CartItemComponent,
    CartModalComponent,
    CartMobileShortcutComponent,
    CtrlTemplateDirective,
    CountdownWatchComponent,
    GoogleMapsModule,
    PipesModule,
    NumberInputModule,
    CountryAndRegionsModule,
    ConfirmModalModule,
    DirectivesModule,
    DateSelectModule,
  ],
})
export class SharedModule {}
