import { ChildViewModel } from '@fgb/core';
import { ChildAction } from './child-action';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Child upgrade action, navigates to the child upgrade page
 */
export class ChildUpgradeAction implements ChildAction {
    websiteContent: string = 'child.panel.upgrade.button';
    className = 'child-upgrade-btn';
    icon = 'arrow_circle_up';

    constructor(protected child: ChildViewModel){

    }

    /**
     * Action performed when the button is clicked
     */
    invoke(): void {
        const router = inject(Router);
        router.navigate(['/child/upgrade']);
    }

    // Show button if AccountPaymentState is 0
    get visible(): boolean {
        return this.child.AccountPaymentState === 0;
    }
}