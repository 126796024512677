<div
  class="my-4"
  [ngClass]="{
    portrait: displayType === answerDisplayType.ImageAndText
  }"
>
  <div class="survey-answer">
    <div class="survey-answer-container justify-content-center pending">
      <div class="survey-answer-item w-100" *ngFor="let answer of selectedAnswers">
        <ng-container [ngSwitch]="displayType">
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <img
              class="image-container border-0 border-bottom"
              src="{{ answer.ImageURL | contentImage : '7' }}"
              alt="{{ answer.ImageURL }}"
            />
            <div class="answer-text text-center text-white bg-secondary">
              <span>{{ answer.Title }}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="answer-text-landscape text-center text-white bg-secondary">
              <span>{{ answer.Title }}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="answerDisplayType.Image">
            <img class="image-container rounded-4" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="text-center fw-bold font-size-12 mt-4 mb-2 submitted-text">
      {{ 'survey.predictor.pending' | translate : { fgbdefault: 'Thank you for submitting your selection' } }}
    </div>
    <div class="text-center font-size-12 mb-lg-5 mb-3 instructions">
      {{
        'survey.predictor.pending.instructions'
          | translate : { fgbdefault: 'Please come back after the conclusion of this predictor to view your results.' }
      }}
    </div>
  </div>
</div>
