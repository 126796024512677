import { Component, inject } from '@angular/core';
import { PartnerAddFormComponent } from '../../../../modules/user/components/partner-add-form/partner-add-form.component';
import { firstValueFrom } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-add-partner-modal',
  templateUrl: './add-partner-modal.component.html',
  styleUrl: './add-partner-modal.component.scss'
})
export class AddPartnerModalComponent extends PartnerAddFormComponent{
  private modalService: NgbActiveModal = inject(NgbActiveModal);

  // Override the base class submit method to close the modal after the form is submitted successfully
  override submit() {
    this.addForm.markAllAsTouched();
    this.errorText = '';
    let pendingExists = false;
    this.pendingPartnerEmails.forEach((pendingEmail) => {
      if (this.addForm.controls.email.value.toLowerCase() === pendingEmail) {
        pendingExists = true;
        return;
      }
    });
    
    if (pendingExists) {
      this.errorText = 'This email is already used for an existing pending partner';
    } else {
      const fullName: string = this.addForm.value.fullName.trim();
      const email: string = this.addForm.value.email.trim();

      if (this.addForm.valid) {
        firstValueFrom(this.partnerPendingService.add(fullName, email, ''))
          .then(() => {
            // Close the modal after the form is submitted successfully
            this.closeModal();
          })
          .catch((e) => {
            this.errorText = e.error && !!e.error.Message ? e.error.Message : e.message;
          });
      }
    }
  }

  closeModal(): void{
    this.modalService.dismiss();
  }
}
