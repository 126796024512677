<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card text-white">
    <div class="d-flex flex-column h-100">
      <div class="content-wrapper">
        <!--Name and ID-->
        <div class="card-id text-start">
          <p class="mb-0 text-secondary card-name w-100" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>
              <label *inPopulation="[populations.Orange]">
                <div>
                  <label class="mb-1 fw-normal">{{
                    'membercard.productcode.orange' | translate : { fgbdefault: 'Season' }
                  }}</label>
                </div>
              </label>
              <label *inPopulation="[populations.Default]">
                <div>
                  <label class="mb-1 fw-normal">{{
                    'membercard.productcode.fan' | translate : { fgbdefault: 'Ducks Fan' }
                  }}</label>
                </div>
              </label>
              <label *inPopulation="[populations.Partial]">
                <div>
                  <label class="mb-1 fw-normal">{{
                    'membercard.productcode.partial' | translate : { fgbdefault: 'Plan Holder' }
                  }}</label>
                </div>
              </label>
              <label *inPopulation="[populations.Partner]">
                <div>
                  <label class="mb-1 fw-normal">{{
                    'membercard.productcode.partner' | translate : { fgbdefault: 'Partner' }
                  }}</label>
                </div>
              </label>
            </div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <img *inPopulation="populations.Orange" class="logo" src="assets/images/logos/OA_member.png" />
        <img *notInPopulation="populations.Orange" class="logo" src="assets/images/logos/ducks_logo.png" />
      </div>
      <ng-container *notInPopulation="[populations.Default]">
        <div class="details-content-wrapper mt-1">
          <!--Balances-->
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-end mb-1">
              <ng-container *inPopulation="[populations.Orange, populations.Partner]">
                <div class="card-account-no text-start pe-3" *ngIf="pointsBalance$">
                  <label class="value mb-0">{{ pointsBalance$ | async | number }}</label>
                  <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
                </div>
                <div class="text-start ps-3 pe-4" *ngIf="ecash$">
                  <label class="value mb-0">{{ ecash$ | async | FortressCurrencyPipe }}</label>
                  <div class="font-size-12">{{ 'member.card.ecash.balance' | translate }}</div>
                </div>
              </ng-container>
              <ng-container *notInPopulation="[populations.Default]">
                <div class="text-start pe-3">
                  <label class="value mb-0">{{ memberCard?.DiscountLevel ?? 0 }}%</label>
                  <div class="font-size-12">{{ 'member.card.discount.label' | translate }}</div>
                </div>
                <div class="text-start ps-3 pe-3">
                  <label class="value mb-0">{{ memberCard?.RetailDiscountLevel ?? 0 }}%</label>
                  <div class="font-size-12">{{ 'member.card.retail.discount.label' | translate }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
