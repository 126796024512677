<div class="portal-product-success">
  <div class="portal-product-content">
    <div class="left-panel">
      <h5 class="text-success">{{ 'registration.success.page.title' | translate }}</h5>
      <p class="registration-success-message font-size-14">{{ 'registration.success.page.message' | translate }}</p>
      <button class="btn back-btn btn-primary text-white fw-bold" [routerLink]="['/guardians']">
        {{ 'fgbcl.registration.success.page.back.button' | translate }}
      </button>
    </div>
  </div>
</div>

<!-- LOADING SPINNER  -->
<ng-template #loading>
  <div class="text-center p-4">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
