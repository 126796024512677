import { Component, OnInit, Input } from '@angular/core';
import { SurveyType } from '@fgb/core';
import { Survey } from '@fgb/core';
import * as moment from 'moment';

export enum SurveyDisplayStatus{
  ComingSoon
}

@Component({
  selector: 'fgb-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss', '../survey.scss'],
})
export class SurveyItemComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;
  status: SurveyDisplayStatus;

  constructor() {}

  ngOnInit() {
    let today = moment(moment.utc()).local();
    if( this.survey.SeparateEntryWindow ){
      let entryStartDate = moment(moment.utc(this.survey.EntryStartDate)).local();

      if( today.isBefore(entryStartDate) ){
        this.status = SurveyDisplayStatus.ComingSoon;
      }
    }else{
      let startDate = moment(moment.utc(this.survey.StartDate)).local();

      if( today.isBefore(startDate) ){
        this.status = SurveyDisplayStatus.ComingSoon;
      }
    }
  }
}
