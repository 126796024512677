import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, SportsAllianceAuthService } from '@fgb/core';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-sports-alliance-callback',
  templateUrl: './sports-alliance-callback.component.html',
  styleUrls: ['./sports-alliance-callback.component.scss'],
})
export class SportsAllianceCallbackComponent implements OnInit {
  cookieName: string = '_sa_sso_upid';
  constructor(
    private sportsAllianceService: SportsAllianceAuthService,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      // already logged in
      this.router.navigate(['/']);
    } else if (this.sportsAllianceService.hasNamedCookie(this.cookieName)) {
      // login through cookies
      let domainCookie = this.getDomainCookie();
      this.authenticateDomainCookie(domainCookie);
    } else {
      // log through a token
      this.authenticateJwt();
    }
  }

  /**
   * make request to authenticate domain
   * cookie if found.
   * If failed then will call authenticate JWT.
   * @param domainCookie domain cookie to
   * be authenticated.
   */
  authenticateDomainCookie(domainCookie: string) {
    firstValueFrom(this.sportsAllianceService.loginUsingToken(domainCookie)).catch(() => {
      this.authenticateJwt();
    });
  }

  /**
   * call to athenticate JWT
   * if failed response will return
   * user to login page.
   */
  authenticateJwt() {
    let jwtToken = this.getProviderJwtToken('tokenpayload');
    firstValueFrom(this.sportsAllianceService.validateJwt(jwtToken)).catch(() => {
      this.router.navigate(['/login']);
    });
  }

  /**
   * Get a query string parameter without case sensitivity.
   * @param parameter the parameter that you require the information for
   */
  getProviderJwtToken(parameter: string): string {
    const url = window.location.href;
    let paramValue;

    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(parameter);
    }

    if (paramValue) {
      return paramValue;
    }
    return '';
  }

  /**
   * Will check if domain cookie exists
   * and return this as a string.
   * @returns domain cookie from
   * sports alliance domain
   */
  getDomainCookie(): string {
    let domainCookie = '';
    domainCookie = this.cookieService.get(this.cookieName);

    return domainCookie;
  }
}
