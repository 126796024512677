import { Component, OnInit } from '@angular/core';
import { SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { SurveyType } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-predictor-page',
  templateUrl: './predictor-page.component.html',
  styleUrls: ['./predictor-page.component.scss'],
})
export class PredictorPageComponent implements OnInit {
  predictors$: Observable<Survey[]>;
  scorePredictors$: Observable<Survey[]>;
  populations = Populations;

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit() {
    this.predictors$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Predictor);
    this.scorePredictors$ = this.surveyQuery.selectRelevantSurveys(SurveyType.ScorePredictor);
  }
}
