import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyAnswer } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-completed-quiz-image',
  templateUrl: './survey-completed-quiz-image.component.html',
  styleUrls: ['./survey-completed-quiz-image.component.scss', '../../../../survey.scss']
})
export class SurveyCompletedQuizImageComponent implements OnInit {
  @Input() survey: Survey;
  @Input() selectedAnswers: SurveyAnswer[];
  @Input() correctAnswer: SurveyAnswer;
  @Input() hasSelectedCorrect: boolean;
  @Input() pointsWon: number;
  populations = Populations;

  constructor() { }

  ngOnInit(): void {
  }

}
