<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-4">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemView]="true"
    [marketplaceItemTypes]="[0, 1, 2, 4]"
    [defaultSort]="0"
  ></fgbcl-marketplace-list>
</div>
