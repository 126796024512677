import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementModel } from '@fgb/core';

@Component({
  selector: 'fgb-announcement-content',
  templateUrl: './announcement-content.component.html',
  styleUrls: ['./announcement-content.component.scss']
})
export class AnnouncementContentComponent implements OnInit {
  @Input() announcement: AnnouncementModel;

  constructor() {}

  ngOnInit() {}
}
