import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopulationQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-game-nav',
  templateUrl: './game-nav.component.html',
  styleUrls: ['./game-nav.component.scss'],
})
export class GameNavComponent implements OnInit {

  link: string;
  screenType = ScreenType;
  isActiveLink: boolean;
  populations = Populations;

  constructor(private router: Router, private populationQuery: PopulationQuery) {}

  ngOnInit() {
      this.link = this.getLinkByPopulation();
      this.isActiveLink = this.router.isActive(this.link, true);
  }

  /**
   * Get the link of challenges depending on the population. By default the link goes to home page.
   */
  getLinkByPopulation(): string{
    const population = this.populationQuery.getCurrentPopulation().DisplayName;

    switch(population){
      case Populations.Orange:
        return '/badges';
      case Populations.Partner:
        return '/badges';
      default:
        return '/';
    }
  }
}
