import { Component, inject } from '@angular/core';
import { PartnerAcceptFormComponent } from '../../../../modules/user/components/partner-accept-form/partner-accept-form.component';
import { firstValueFrom } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-accept-partner-modal',
  templateUrl: './accept-partner-modal.component.html',
  styleUrl: './accept-partner-modal.component.scss'
})
export class AcceptPartnerModalComponent extends PartnerAcceptFormComponent{
  private modalService: NgbActiveModal = inject(NgbActiveModal);

  // Override the base class submit method to close the modal after the form is submitted successfully
  override submit() {
    this.errorText = '';

    if (this.acceptForm.valid) {
      const code: string = this.acceptForm.value.code.trim();
      const secretCode: string = this.acceptForm.value.secretCode.trim();

      firstValueFrom(this.partnerService.confirmPartner(code, secretCode))
        .then(() => {
          firstValueFrom(this.authService.refreshAccessToken()).then(() => {
            this.closeModal();
            // reload to refresh theme and data
            location.reload();
          });
        })
        .catch((e) => {
          this.errorText = e.error && !!e.error.Message ? e.error.Message : e.message;
        });
    }
  }

  closeModal(): void{
    this.modalService.dismiss();
  }
}
