<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length" class="ms-lg-3 column">
    <div *ngFor="let item of items">
      <div class="d-flex flex-row mx-0">
        <img class="shadow rounded" src="{{ item.productData.ItemImageURL | contentImage: '6' }}" />
        <div class="flex-column w-100 ms-3">
          <div class="d-flex flex-row text-start">
            <p class="font-size-12 mb-1">
              {{ item.productData.DisplayName }}
            </p>
          </div>
          <div *ngIf="item.attributes" class="d-flex flex-row mb-1">
            <!-- For each selected attributes-->
            <div
              *ngFor="let attribute of item.attributes.LocalAttributes"
              class="text-start font-size-12 ps-0 text-nowrap mb-0 me-4"
            >
              <span class="fw-bold">{{ attribute.AttributeName }}: </span> {{ attribute.ValueName }}
            </div>
          </div>
          <div *ngIf="item.customFields" class="d-flex flex-row flex-wrap mb-1">
            <!-- For each custom Field-->
            <div *ngFor="let customField of item.customFields">
              <div *ngIf="customField.customFieldValue" class="text-start font-size-12 ps-0 me-4 mb-0">
                <span class="fw-bold">{{ customField.customFieldName }}: </span> {{ customField.customFieldValue }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row w-100">
              <div class="text-start font-size-12 ps-0 text-nowrap col-3">
                <span class="fw-bold">Qty: </span> {{ item.purchaseQuantity }}
              </div>
              <div class="text-start font-size-12 text-nowrap col-3">
                <span class="fw-bold">Points: </span> {{ item.purchaseQuantity * item.productData.PointValue }}
              </div>
            </div>
            <div class="text-end">
              <button (click)="remove(item)" class="btn py-0" attr.aria-label="{{ 'cart.remove.item.screenreader' | translate }}">
                <span class="icon icon-cancel-circle text-danger fw-bold"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="border-disabled w-100 me-lg-2" />
    </div>
  </div>
  <div *ngIf="!items.length" class="font-size-14">
    <p>Your basket is empty!</p>
  </div>
</ng-container>
