import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  MixedLoyaltyWalletItem,
  ProcessProviderId,
  RewardsWalletItem,
  RewardsWalletQuery,
  RewardsWalletService,
} from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable, firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  claimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  isList: boolean = true;
  populations = Populations;
  screenType = ScreenType;

  constructor(private rewardWalletQuery: RewardsWalletQuery, private rewardWalletItemsService: RewardsWalletService) {}

  ngOnInit() {
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
  }
}
