<form
  [formGroup]="acceptForm"
  class="row justify-content-lg-start justify-content-center partner-accept-form"
  (ngSubmit)="submit()"
>
  <div class="col-lg-8 col-12 row p-0 px-lg-2">
    <div class="col-lg-5 col-12">
      <label class="ps-2">{{ 'partner.accept.code' | translate }}</label>
      <input formControlName="code" placeholder="Insert Code Here" class="form-control bg-muted border-0 ps-2" />
      <p
        class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
        *ngIf="acceptForm.get('code')?.hasError('empty') && acceptForm.get('code')?.touched"
      >
        {{ 'partner.accept.form.code.error.empty' | translate : { fgbdefault: 'Partner Code cannot be empty' } }}
      </p>
      <p
        class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
        *ngIf="acceptForm.get('code')?.hasError('required') && acceptForm.get('code')?.touched"
      >
        {{ 'partner.accept.form.code.error.required' | translate : { fgbdefault: 'Partner Code is required' } }}
      </p>
    </div>
    <div class="offset-lg-1 col-lg-5 col-12">
      <label class="ps-2 mt-4 mt-lg-0">{{ 'partner.accept.secret' | translate }}</label>
      <input formControlName="secretCode" placeholder="Insert Code Here" class="form-control bg-muted border-0 ps-2" />
      <p
        class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
        *ngIf="acceptForm.get('secretCode')?.hasError('empty') && acceptForm.get('secretCode')?.touched"
      >
        {{ 'partner.accept.form.secret.code.error.empty' | translate : { fgbdefault: 'Secret Code cannot be empty' } }}
      </p>
      <p
        class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
        *ngIf="acceptForm.get('secretCode')?.hasError('required') && acceptForm.get('secretCode')?.touched"
      >
        {{ 'partner.accept.form.secret.code.error.required' | translate : { fgbdefault: 'Secret Code is required' } }}
      </p>
    </div>
  </div>

  <div class="col-lg-4 d-flex align-items-start">
    <button type="submit" [disabled]="acceptForm.invalid" class="btn btn-block btn-quaternary flex-grow-0 fw-bold me-0 ms-auto">
      {{ 'partner.accept.button' | translate : { fgbdefault: 'Accept Partner' } }}
    </button>

    <div class="col-lg-1 col-2 close-btn">
      <div (click)="close.emit()" class="icon icon-cancel-circle cursor-pointer"></div>
    </div>
  </div>
  <!-- <div class="col-10 col-lg-4 mt-4 mt-lg-0">
    <p class="font-size-10 text-danger mt-1 text-center">
      {{ errorText }}
    </p>
  </div> -->
</form>
