import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart, CartQuery, CartService } from '@fgb/core';

@Component({
  selector: 'fgb-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit {
  items$: Observable<Cart[] | undefined>;
  totalItems$: Observable<number>;
  basketPointsTotal$: Observable<number | undefined>;

  constructor(private cartQuery: CartQuery, private cartService: CartService) {}

  ngOnInit() {
    this.items$ = this.cartQuery.selectAllMarketplaceCartData();
    this.totalItems$ = this.cartQuery.updateTotalItems();
    this.basketPointsTotal$ = this.cartQuery.updateBasketPointsTotal();
  }

  remove(itemToRemove: Cart) {
    this.cartService.remove(itemToRemove.id);
  }
}
