import { Component, OnInit, EventEmitter, Output, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, PartnerService } from '@fgb/core';
import { FGBValidators } from '@fgb/portal-component-library/src/lib/shared/validators';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-partner-accept-form',
  templateUrl: './partner-accept-form.component.html',
  styleUrls: ['./partner-accept-form.component.scss'],
})
export class PartnerAcceptFormComponent implements OnInit {
  acceptForm: FormGroup;
  errorText: string = '';
  @Output() close = new EventEmitter();
  protected fb: FormBuilder = inject(FormBuilder);
  protected partnerService: PartnerService = inject(PartnerService);
  protected authService: AuthService = inject(AuthService);

  ngOnInit() {
    this.acceptForm = this.fb.group({
      code: ['', [Validators.required, FGBValidators.Empty]],
      secretCode: ['', [Validators.required, FGBValidators.Empty]],
    });
  }

  submit() {
    this.errorText = '';

    if (this.acceptForm.valid) {
      const code: string = this.acceptForm.value.code.trim();
      const secretCode: string = this.acceptForm.value.secretCode.trim();

      firstValueFrom(this.partnerService.confirmPartner(code, secretCode))
        .then(() => {
          this.close.emit();
          firstValueFrom(this.authService.refreshAccessToken()).then(() => {
            // reload to refresh theme and data
            location.reload();
          });
        })
        .catch((e) => {
          this.errorText = e.error && !!e.error.Message ? e.error.Message : e.message;
        });
    }
  }
}
