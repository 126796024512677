import { Component, OnInit } from '@angular/core';
import { LoggingService, PurchaseService } from '@fgb/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fgb-portal-product-results-page',
  templateUrl: './portal-product-results-page.component.html',
  styleUrls: ['./portal-product-results-page.component.scss'],
})
export class PortalProductResultsPageComponent implements OnInit {
  constructor(
    private purchaseService: PurchaseService,
    private route: ActivatedRoute,
    private logService: LoggingService,
    public router: Router
  ) {}

  ngOnInit() {
    let orderId: string | null = null;
    if (this.route.snapshot.queryParamMap.keys.map((x) => x.toUpperCase()).includes('ORDERID')) {
      const orderIdQueryStringKey = this.route.snapshot.queryParamMap.keys.filter((x) => x.toUpperCase() === 'ORDERID')[0];
      orderId = this.route.snapshot.queryParamMap.get(orderIdQueryStringKey);
    }

    if (!orderId) {
      this.logService.error('No order ID could be found in the query string');
      return;
    }

      this.purchaseService
        .completeTransaction(true)
        .toPromise()
        .then(() => {
          this.router.navigateByUrl('/portalproducts/success' + `?orderId=${orderId}`);
        })
        .catch(() => {
          this.router.navigate(['/portalproducts/failure']);
        });
    
  }
}
