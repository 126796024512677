<div class="survey-container rounded poll">
  <div class="survey-header text-center">
    <div *ngIf="survey.ImageURL" class="image-container-polls w-100 overflow-hidden position-relative bg-white rounded-top">
      <img class="h-100 w-100" src="{{ survey.ImageURL | contentImage : '7' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <div class="text-white text-center pt-4">
      <h3 class="mb-lg-3 text-uppercase">{{ 'poll.title' | translate }}</h3>
    </div>
    <div class="survey-info">
      <div class="d-flex align-items-center w-100 position-relative">
        <div class="d-flex align-items-center question-container">
          <div class="gIcon-polls material-icons inline-block my-0 text-white">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-flex text-white survey-question">
          <p>
            {{ survey.SurveyQuestion }}
          </p>
        </div>

        <ng-container *notInPopulation="[populations.Partial, populations.Default, populations.Kids]">
          <div
            class="text-center fw-bold text-white font-size-16 points"
            [ngClass]="pointsWon > 0 ? 'd-block d-lg-none' : 'd-none'"
          >
            +{{ pointsWon }} {{ 'poll.points' | translate }}
          </div>
        </ng-container>
        <div
          class="text-end text-white countdown-container d-flex align-items-center"
          [ngClass]="{ countdown: survey.HasBeenAnsweredByUser }"
        >
          <ng-container *ngIf="status !== displayStatus.ComingSoon && !survey.HasBeenAnsweredByUser">
            <i class="material-icons me-2">schedule</i>
            <fgbcl-countdown [endDateTime]="survey.EndDate" [isUTC]="true" class="font-size-14 fw-bold"></fgbcl-countdown>
          </ng-container>
        </div>
      </div>
      <div class="survey-question d-flex d-lg-none mt-3 text-white font-size-14 text-start">
        {{ survey.SurveyQuestion }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="status != displayStatus.ComingSoon; else comingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>

<ng-template #comingSoon>
  <div class="coming-soon d-flex align-items-center justify-content-center">
    <p class="text-center fw-bold text-primary m-0">
      {{ 'survey.item.quiz.coming.soon.text' | translate : { fgbdefault: 'Coming soon' } }}
    </p>
  </div>
</ng-template>
