import { Component, OnInit } from '@angular/core';
import { AnnouncementModel } from '@fgb/core';
import { AnnouncementQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-announcements',
  templateUrl: 'announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit {
  announcements$: Observable<AnnouncementModel[]>;
  customOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    responsive: {
      900: { autoWidth: false, autoHeight: false },
    },
  };

  constructor(private announcementQuery: AnnouncementQuery) {}

  ngOnInit() {
    this.announcements$ = this.announcementQuery.announcements();
  }
}
