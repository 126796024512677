<div class="department-container overflow-hidden">
  <div class="row no-gutters">
    <div class="col-12 col-lg-6">
      <div class="department-image">
        <img class="w-100 rounded" src="{{ offer.ImageUrl | contentImage: '23' }}" alt="" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="department-content">
        <div class="department-title text-primary fw-bold text-start mt-3 mt-lg-0">
          {{ languageContent.Name }}
        </div>
        <div class="description-container">
          <fgbcl-short-long-description [description]="languageContent.Description"></fgbcl-short-long-description>
        </div>
        <div class="d-flex mt-2">
          <div
            *ngIf="
              offer.OfferType == companyOfferType.ExternalWebsite ||
              offer.OfferType == companyOfferType.Affiliate ||
              offer.OfferType == companyOfferType.SingleSignOn
            "
          >
            <button
              id="redeem-offer-button"
              class="
                btn-primary
                shop-now
                font-size-14
                text-center
                d-flex
                justify-content-center
                align-items-center
                text-white
                fw-bold
                me-4
                mb-4
                mt-4
                rounded
              "
              (click)="claimOffer(offer.OfferId)"
            >
              {{ 'offers.redeem.button' | translate }}
            </button>
            <fgbcl-errors></fgbcl-errors>
          </div>
          <ng-container *ngIf="offer.TermsAndConditionsType == termsAndConditionsType.Text">
            <p
              id="terms-modal-link"
              class="font-size-14 cursor-pointer text-black fw-bold mt-3 pt-3"
              (click)="openOfferTermsModal(offer)"
            >
              {{ 'offers.terms.link' | translate }}
            </p>
          </ng-container>
          <ng-container *ngIf="offer.TermsAndConditionsType == termsAndConditionsType.Url">
            <div>
              <a id="terms-external-link" href="{{ offer.TermsAndConditions }}" target="_blank">
                <p class="font-size-14 mb-0 text-black fw-bold mt-3 pt-3">{{ 'offers.terms.link' | translate }}</p>
              </a>
              <div class="font-size-16 text-success fw-bold">
                {{ successMessage }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
