<ng-container *notInPopulation="[populations.Kids]">
  <div class="banner d-lg-flex d-none">
    <div class="banner-logo"></div>
  </div>
</ng-container>

<ng-container *inPopulation="[populations.Kids]"
  ><div class="banner d-flex">
    <div class="banner-logo"></div>
  </div>
</ng-container>

<ng-container *inPopulation="[populations.Orange, populations.Partner, populations.Partial, populations.Kids]">
  <div *ifScreenSize="screenType.Desktop">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </div>
</ng-container>

<div class="d-block" *ifScreenSize="screenType.Mobile">
  <div class="container pt-4">
    <fgb-barcode [showWalletButton]="false"></fgb-barcode>
  </div>
</div>

<ng-container
  *inPopulation="[populations.Orange, populations.Partner, populations.Kids, populations.Partial, populations.Default]"
>
  <!-- Rewards -->
  <ng-container
    *ngIf="
      {
        walletItemsUnclaimed: walletItemsUnclaimed$ | async
      } as walletItems;
      else noWalletItems
    "
  >
    <div class="wallet-redeem-carousel pt-4">
      <div class="container">
        <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
          <fgbcl-rewards-wallet-redeem-list
            [unRedeemedItems]="walletItems.walletItemsUnclaimed"
          ></fgbcl-rewards-wallet-redeem-list>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #noWalletItems>
    <div class="container">
      <h5 class="no-wallet-items mt-2 mb-3 fw-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
      <div class="no-items w-100 mt-4 no-results-placeholder-div">
        <div class="no-results-placeholder">
          {{ 'rewards.wallet.no.items.description' | translate }}
        </div>
      </div>
    </div>
  </ng-template>

  <div class="container mt-3 text-center">
    <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
      {{ 'rewards.wallet.view.claimed' | translate }}
    </a>
  </div>
</ng-container>
