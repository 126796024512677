<div class="ecash-description-box rounded shadow py-3 ps-4 pe-1 px-lg-4">
  <h5 class="text-center text-white fw-bold">{{ 'payment.description.title' | translate }}</h5>
  <hr class="vertical-line" />
  <div class="d-flex flex-row flex-wrap flex-lg-nowrap justify-content-lg-around mt-3 ms-2 text-white ecash-description-steps">
    <!--Col 1-->
    <div class="d-flex flex-column flex-nowrap mx-2 py-2">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <div class="ecash-description-icon centered position-relative">
          <div class="ecash-description-number position-absolute centered color-1">1</div>
          <span class="icon material-icons ecash-description color-1" aria-hidden="true">{{ googleIconName1 }}</span>
        </div>
        <div class="font-size-12 ps-3 pe-lg-4">{{ 'payment.description.step.one' | translate }}</div>
      </div>
    </div>
    <!--Col 2-->
    <div class="d-flex flex-column flex-nowrap mx-2 py-2">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <div class="ecash-description-icon centered position-relative">
          <div class="ecash-description-number position-absolute centered color-2">2</div>
          <span class="icon material-icons ecash-description color-2" aria-hidden="true">{{ googleIconName2 }}</span>
        </div>
        <div class="font-size-12 ps-3 pe-lg-4">{{ 'payment.description.step.two' | translate }}</div>
      </div>
    </div>
  </div>
</div>
