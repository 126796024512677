import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-worksheet-page',
  templateUrl: './worksheet-page.component.html',
  styleUrls: ['./worksheet-page.component.scss']
})
export class WorksheetPageComponent implements OnInit {
  screenType = ScreenType;

  constructor() { }

  ngOnInit() {
  }

}
