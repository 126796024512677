<div class="d-block d-lg-none">
  <div class="container pt-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<nav class="nav nav-secondary account-details-nav mt-4 mt-lg-0">
  <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>

  <ng-container *inPopulation="[populations.Orange, populations.Partner]">
    <a class="nav-item" [routerLink]="['/activity']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <div class="icon material-icons">history</div>
      <label>{{ 'account.nav.activity' | translate }}</label>
    </a>
  </ng-container>

  <a
    class="nav-item"
    [routerLink]="['/partners']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.partners.screenreader' | translate : { fgbdefault: 'Move to partners page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">people</div>
    <label>{{ 'account.nav.partners' | translate }}</label>
  </a>
</nav>
