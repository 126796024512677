<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<div class="container mt-4">
  <ng-container *notInPopulation="[populations.Kids]">
    <ng-container *ngIf="isGuardian; else notGuardian">
      <div>
        <fgb-child-list></fgb-child-list>
      </div>
      <ng-container
        *ngTemplateOutlet="
          cardTemplate;
          context: {
            title: 'guardian.page.guardian.title' | translate,
            description: 'guardian.page.guardian.description' | translate | markdownTranslate
          }
        "
      >
      </ng-container>
    </ng-container>
    <ng-template #notGuardian>
      <div class="text-start my-2">
        <ng-container
          *ngTemplateOutlet="
            cardTemplate;
            context: {
              title: 'guardian.page.not.guardian.title' | translate,
              description: 'guardian.page.not.guardian.description' | translate | markdownTranslate
            }
          "
        >
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>

<!-- Card Template -->

<ng-template #cardTemplate let-title="title" let-message="message" let-description="description">
  <div class="card border rounded overflow-hidden guardian-info text-start my-4">
    <div class="img-wrapper">
      <img alt="" class="w-100 h-100" src="assets/images/kids/wild-wingers-guardians.png" />
    </div>
    <div class="description">
      <h5 class="text-black">{{ title }}</h5>
      <p class="font-size-14 mt-2" [innerHtml]="description"></p>
      <button
        *ngIf="!isGuardian"
        (click)="openGuardianModal()"
        class="btn btn-primary btn-guardian font-size-14 text-center text-white mt-auto w-100 fw-bold"
      >
        {{ 'guardian.page.button.register.as.guardian' | translate : { fgbdefault: 'Register as a Guardian' } }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Guardian Modal -->

<ng-template #guardianModal>
  <div class="guardian-modal-header">
    <h5 class="guardian-modal-title font-size-14" id="modal-basic-title">
      {{ 'guardian.page.modal.title' | translate }}
    </h5>
  </div>
  <hr />
  <div class="modal-body">
    <p class="guardian-modal-prompt">
      {{ 'guardian.page.modal.prompt.message' | translate }}
    </p>
    <hr />
    <div class="action-wrapper">
      <button class="btn btn-danger cancel-btn" (click)="modal.close()">
        {{ 'guardian.page.modal.button.cancel' | translate }}
      </button>
      <button class="btn btn-success cancel-btn" (click)="becomeGuardian()">
        {{ 'guardian.page.modal.button.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>
