<div class="container d-flex align-items-center">
  <div class="card shadow border-bottom-0 mx-auto card-link-fail-box">
    <div class="card-body text-center py-4">
      <div class="icon material-icons text-danger">credit_card</div>
      <h3 class="card-heading text-danger">{{ 'ducks.pay.link.fail.title' | translate }}</h3>
      <div class="my-4 sub-heading-modal">
        <div class="fw-bold">{{ 'ducks.pay.link.fail.info' | translate }}</div>
      </div>
      <div class="btn-container mt-2 d-block">
        <button [routerLink]="['/payment']" type="button" class="btn btn-danger mx-2 mt-4 btn-text fw-bold">
          {{ 'back.to.ducks.pay.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
