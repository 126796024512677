
import { AuthService, ChildViewModel } from "@fgb/core";
import { ChildAction } from "./child-action";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

/**
 * Child login action, switches to the child account
 */
export class ChildLoginAction implements ChildAction{
    websiteContent: string = 'child.panel.login.button';
    className = 'child-login-btn';

    constructor(protected child: ChildViewModel){

    }

    /**
     * Action performed when the button is clicked
     */
    invoke(): void{
        const router = inject(Router);
        const authService = inject(AuthService);
        
        firstValueFrom(authService.switchUser(this.child.MemberId, false))
        .then(() => router.navigate(['/']))
        .then(() => location.reload());
    }

    // Show button if AccountPaymentState is 1
    get visible(): boolean{
        return this.child.AccountPaymentState === 1;
    }

}