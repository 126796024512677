<fgb-navigation-indicator></fgb-navigation-indicator>
<div class="PopluationId-{{ populationId }}">
  <ng-container *ngIf="!navigationSettings?.mobile.hidden">
    <fgb-mobile-nav-menu *ifScreenSize="screenType.Mobile"></fgb-mobile-nav-menu>
  </ng-container>

  <ng-container *ngIf="!navigationSettings?.desktop.hidden">
    <fgb-nav-bar *ifScreenSize="screenType.Desktop"></fgb-nav-bar>
  </ng-container>
</div>

<fgbcl-notification-toast></fgbcl-notification-toast>

<div class="PopluationId-{{ populationId }}">
  <div id="main" role="main">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="PopluationId-{{ populationId }}">
  <fgb-footer></fgb-footer>
</div>
