<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <ng-container *notInPopulation="[populations.Default, populations.Partial]">
    <a
      class="nav-item"
      [routerLink]="['/rewards/marketplace']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'marketplace'"></fgbcl-loyalty-icon>
      <label>
        {{ 'rewards.nav.marketplace' | translate }}
      </label>
    </a>
  </ng-container>
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="3"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.events' | translate }}</label>
  </a>
  <ng-container *inPopulation="[populations.Partner, populations.Orange]">
    <a
      class="nav-item"
      [routerLink]="['/rewards/auctions']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'auction'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.auctions' | translate }}</label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/raffles']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'lotto'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.lotto' | translate }}</label>
    </a>
  </ng-container>
  <ng-container *ifScreenSize="screenType.Desktop">
    <a
      class="nav-item"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }}</label>
    </a>
  </ng-container>
</nav>
