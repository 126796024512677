<div class="add-partner-modal">
  <div class="modal-header">
    <h5>{{ 'add.partner.modal.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <form [formGroup]="addForm" class="partner-add-form" (ngSubmit)="submit()">
      <div class="form-field">
        <div class="form-floating">
          <input formControlName="fullName" class="form-control" />
          <label>{{ 'partner.add.form.fullname.label' | translate : { fgbdefault: 'Partner Full Name' } }}</label>
        </div>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="addForm.get('fullName')?.hasError('required') && addForm.get('fullName')?.touched"
        >
          {{ 'partner.add.form.fullname.error.required' | translate : { fgbdefault: 'Fullname is required' } }}
        </p>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="addForm.get('fullName')?.hasError('empty') && addForm.get('fullName')?.touched"
        >
          {{ 'partner.add.form.fullname.error.empty' | translate : { fgbdefault: 'Fullname cannot be empty' } }}
        </p>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="addForm.get('fullName')?.hasError('pattern') && addForm.get('fullName')?.touched"
        >
          {{ 'partner.add.form.fullname.error.pattern' | translate : { fgbdefault: 'Fullname is invalid' } }}
        </p>
      </div>
      <div class="form-field">
        <div class="form-floating">
          <input formControlName="email" class="form-control" />
          <label>{{ 'partner.add.form.email.label' | translate : { fgbdefault: 'Partner Email' } }}</label>
        </div>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="addForm.get('email')?.hasError('required') && addForm.get('email')?.touched"
        >
          {{ 'partner.add.form.email.error.required' | translate : { fgbdefault: 'Email is required' } }}
        </p>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="addForm.get('email')?.hasError('pattern') && addForm.get('email')?.touched"
        >
          {{ 'partner.add.form.email.error.pattern' | translate : { fgbdefault: 'Email is invalid' } }}
        </p>
      </div>
      <p class="col-12 font-size-10 text-danger mt-1 mb-0" *ngIf="errorText">
        {{ errorText }}
      </p>
    </form>
    <p class="col-12 font-size-10 text-danger mt-1" *ngIf="errorText">
      {{ errorText }}
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn text-danger" (click)="closeModal()">{{ 'partner.modal.cancel.button' | translate }}</button>
    <button class="btn btn-success" (click)="submit()" [disabled]="addForm.invalid">
      {{ 'partner.modal.confirm.button' | translate }}
    </button>
  </div>
</div>
