import { Component, DestroyRef, inject, OnInit, Renderer2 } from '@angular/core';
import { LoggingService, MemberQuery, NotificationCenterService, PopulationService, SignalRHub } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NavigationService, NavigationSettings } from '@fgb/portal-component-library/src/lib/shared/services';
import { firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit {
  private _destroyRef = inject(DestroyRef);
  screenType = ScreenType;
  populationId = 0;
  navigationSettings: NavigationSettings;

  constructor(
    private signalRHub: SignalRHub,
    private logService: LoggingService,
    private memberQuery: MemberQuery,
    private notificationCenterService: NotificationCenterService,
    private populationService: PopulationService,
    private renderer: Renderer2,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.notificationCenterService.fetchNotificationCenter(5).pipe(takeUntilDestroyed(this._destroyRef)).subscribe();

    this.populationId = this.memberQuery.getPopulationId();
    firstValueFrom(this.populationService.fetchPopulations());

    this.setTheme();

    // Subscribe to navigation changes
    this.navigationService.onNavigationChanged
      .pipe(
        takeUntilDestroyed(this._destroyRef)
      ).subscribe((navigationSettings) => {
        this.navigationSettings = navigationSettings;
      });
  }

  setTheme(): void {
    this.renderer.addClass(document.body, this._getTheme(this.memberQuery.getUserContext().populationName as Populations));
  }

  /**
   * Get theme class name based on population
   */
  private _getTheme(population: Populations): string {
    const suffix = 'theme';
    switch (population) {
      case Populations.Orange:
        return `orange-alliance-${suffix}`;
      case Populations.Partial:
        return `partial-plan-${suffix}`;
      case Populations.Partner:
        return `partner-${suffix}`;
      case Populations.Kids:
        return `wild-wingers-${suffix}`;
      default:
        // Fan as default theme
        return `fan-${suffix}`;
    }
  }
}
