<!--Poimt Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/marketplace'" [backButtonTitle]="'Raffles'"></fgb-points-summary-bar>
</div>
<!--Back Button-->
<div class="container">
  <div
    class="text-danger pt-3 reward-back-btn cursor-pointer d-none d-lg-flex w-20 align-items-center"
    [routerLink]="'/rewards/marketplace'"
  >
    <i class="icon material-icons">chevron_left</i>
    <span class="font-size-12 fw-bold">{{ 'back.to.rewards' | translate }}</span>
  </div>
</div>
<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>
