<a href="{{ announcement.LinkUrl }}" class="announcements-container row w-100 rounded overflow-hidden shadow bg-white">
  <ng-container *ngIf="announcement.Image">
    <div class="col-lg-6">
      <img class="announcement-image" src="{{ announcement.Image | contentImage: '23' }}" alt="{{ announcement.Image }}" />
    </div>
  </ng-container>
  <div class="col-lg-6 announcements-text font-size-14 text-start">
    <h3 class="announcements-title mb-3 font-size-14 fw-bold text-capitalize">{{ announcement.Title }}</h3>
    <div class="announcements-info" [innerHTML]="announcement.Description"></div>
  </div>
</a>
