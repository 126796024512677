<div class="accept-partner-modal">
  <div class="modal-header">
    <h5>{{ 'accept.partner.modal.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="acceptForm"
      class="row justify-content-lg-start justify-content-center partner-accept-form"
      (ngSubmit)="submit()"
    >
      <div class="form-field">
        <div class="form-floating">
          <input formControlName="code" class="form-control" />
          <label>{{ 'partner.accept.code' | translate }}</label>
        </div>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="acceptForm.get('code')?.hasError('empty') && acceptForm.get('code')?.touched"
        >
          {{ 'partner.accept.form.code.error.empty' | translate : { fgbdefault: 'Partner Code cannot be empty' } }}
        </p>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="acceptForm.get('code')?.hasError('required') && acceptForm.get('code')?.touched"
        >
          {{ 'partner.accept.form.code.error.required' | translate : { fgbdefault: 'Partner Code is required' } }}
        </p>
      </div>
      <div class="form-field">
        <div class="form-floating">
          <input formControlName="secretCode" class="form-control" />
          <label>{{ 'partner.accept.secret' | translate }}</label>
        </div>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="acceptForm.get('secretCode')?.hasError('empty') && acceptForm.get('secretCode')?.touched"
        >
          {{ 'partner.accept.form.secret.code.error.empty' | translate : { fgbdefault: 'Secret Code cannot be empty' } }}
        </p>
        <p
          class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
          *ngIf="acceptForm.get('secretCode')?.hasError('required') && acceptForm.get('secretCode')?.touched"
        >
          {{ 'partner.accept.form.secret.code.error.required' | translate : { fgbdefault: 'Secret Code is required' } }}
        </p>
      </div>
      <p class="col-12 font-size-10 text-danger mt-1 mb-0" *ngIf="errorText">
        {{ errorText }}
      </p>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn text-danger" (click)="closeModal()">{{ 'partner.modal.cancel.button' | translate }}</button>
    <button class="btn btn-success" (click)="submit()" [disabled]="acceptForm.invalid">
      {{ 'partner.modal.confirm.button' | translate }}
    </button>
  </div>
</div>
