<div class="banner" role="banner">
  <h1>{{ 'pick.player.leaderboard.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="leaderboard-back-btn fw-bold d-flex d-lg-none my-2">
    <a
      [routerLink]="[backButtonLink]"
      class="btn btn-primary flex-grow-0 flex-shrink-0 d-flex align-items-center justify-content-center h-100 pe-2 px-3 py-2 fw-bold me-2"
    >
      <span class="icon-arrow-1-left fw-bold"></span>
    </a>

    <fgb-fantasy-leaderboard></fgb-fantasy-leaderboard>
    <div class="d-none d-lg-block">
      <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center fw-bold px-0 mt-2">
        <span class="pe-2 icon-arrow-1-left text-primary fw-bold"></span>
        <span class="fw-bold font-size-14 text-primary">{{ 'pick.player.back.button' | translate }}</span>
      </a>
    </div>
  </div>
</div>
