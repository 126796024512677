import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-ecash-description',
  templateUrl: './ecash-description.component.html',
  styleUrls: ['./ecash-description.component.scss'],
})
export class EcashDescriptionComponent implements OnInit {
  @Input() googleIconName1: string = 'credit_card';
  @Input() googleIconName2: string = 'qr_code_scanner';
  @Input() googleIconName3: string = 'emoji_events';

  constructor() {}

  ngOnInit(): void {}
}
