import { Component, OnInit, Input } from '@angular/core';
import {
  Barcode,
  BarcodeQuery,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  SeatingDetailQuery,
  SeatingDetail,
  CardToken,
  CardTokenQuery,
} from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { BalanceQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

export interface BarcodeOptions {
  // Show or hide seating arrangement which includes seat number, row and block.
  showSeating: boolean;
  showLogo: boolean;
  showDiscount: boolean;
  showWalletButton: boolean;
}

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() memberId: string;
  @Input() primaryTheme: boolean = false;
  @Input() showCashBackToggle: boolean = false;
  @Input() showMobilePass: boolean = false;
  @Input() set options(value: BarcodeOptions) {
    this.barcodeOptions = { ...this.barcodeOptions, ...value };
  }
  @Input() showWalletButton: boolean = true;
  @Input() showAppleWallet: boolean = false;
  barcodeOptions: BarcodeOptions = { showSeating: false, showLogo: true, showDiscount: false, showWalletButton: true };
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  seatingDetail$: Observable<SeatingDetail | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  ecash$: Observable<number | undefined>;
  pointsBalance$: Observable<number | undefined>;
  config: any = config;
  cardToken$: Observable<CardToken>;
  populations = Populations;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private seatingDetailQuery: SeatingDetailQuery,
    private memberQuery: MemberQuery,
    private cardTokenQuery: CardTokenQuery
  ) {}

  ngOnInit() {
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    firstValueFrom(this.barcodeService.fetchBarcode());
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(this.config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(this.config.purseConfig.combined);
    this.cardToken$ = this.cardTokenQuery.selectDefaultCard();
  }
}
