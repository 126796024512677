<div *ngIf="lottoItem; else noPurchase">
  <div class="item-details d-flex flex-grow-1 justify-content-center flex-shrink-1 p-3 mt-lg-4 mt-1">
    <div>
      <div class="text-success mb-1 fw-bold font-size-18">
        {{ 'lotto.entry.successful' | translate }}
      </div>
      <p class="mb-0 text-black font-size-14 text-left pe-lg-4 rewards-success-description">
        {{ 'lotto.entry.successful.message' | translate }}
      </p>
      <a class="btn btn-primary confirm-btn fw-bold mt-4" [routerLink]="['/rewards/wallet']">{{
        'rewards.back.to.wallet' | translate
      }}</a>
    </div>

    <div class="border rounded payment-details">
      <img
        class="purchase-success-image rounded me-4"
        src="{{ lottoItem.ImageName | contentImage : '6' : 'lotto' }}"
        alt="{{ lottoItem.ImageName }}"
        [useDefault]="true"
        [loyaltyType]="'lotto'"
        draggable="false"
      />
      <div class="item-info">
        <div class="font-size-14 fw-bold mb-lg-3 mb-2 mt-4 mt-lg-1">{{ lottoItem.Title }}</div>
        <div class="d-flex align-items-center">
          <span class="pe-2 rewards-details-spacing font-size-12">{{ 'lotto.entries' | translate }}</span>
          <span class="fw-bold item-info-value font-size-14">{{ quantity | number }}</span>
        </div>
        <div class="d-flex align-items-center mt-2 mb-4 mb-lg-0">
          <span class="pe-2 rewards-details-spacing font-size-12">{{ 'lotto.points.used' | translate }}</span>
          <span class="fw-bold item-info-value font-size-14">{{ totalPrice | number }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">{{ 'no.purchased.item.found' | translate }}</div>
</ng-template>
