<div class="container campaign-details">
  <div class="d-flex mt-3 pt-3">
    <div class="cursor-pointer back-button" [routerLink]="'/rewards/wallet'">
      <i class="icon material-icons fw-bold pe-2">chevron_left</i>
      <span class="font-size-14 fw-bold">{{ 'campaign.back.to.wallet' | translate }}</span>
    </div>
    <!-- Image -->
    <div class="img-wrapper">
      
      <div class="loyalty-details-image border-0 rounded overflow-hidden p-0">
        <img
          [ngClass]="{
            'image-opacity': currentCampaign?.campaignRedemptions_Quantity == 0
          }"
          alt=""
          [src]="currentCampaign?.image_URL"
          class="logo"
        />
      </div>
      <div class="icon-placement">
        <ng-container *ngIf="currentCampaign?.campaignRedemptions_Quantity == 0">
          <span class="icon material-icons text-grey text-end fw-bold ms-3"> lock </span>
        </ng-container>
        <ng-container *ngIf="currentCampaign?.campaignRedemptions_Quantity > 0">
          <span class="icon material-icons text-success text-end fw-bold ms-3"> check_circle </span>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Name -->
  <div class="text-center font-size-14 fw-bold mt-3">{{ currentCampaign?.CampaignName }}</div>
  <!-- Expiry -->
  <div class="font-size-12 text-danger text-center pt-lg-2 pt-0">
    {{ 'offer.expiry' | translate }} {{ currentCampaign?.Campaign_ExpiryDate | date : 'MM/dd/YYYY' }}
  </div>
  <!-- Description -->
  <hr class="border-top border-disabled my-3" />
  <label class="pt-2">{{ 'offer.description' | translate }}</label>
  <div class="font-size-14">{{ currentCampaign?.PortalDescription }}</div>
</div>
