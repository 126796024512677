import { Component, Inject, InjectionToken, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService, ProductIntervalPeriodType } from '@fgb/core';
import { BasketService, PortalProductUpgradeChildBasketItem } from 'src/app/pages/child-page/basket.service';

/**
 * Helper class to set a value for an InjectionToken
 */
class Setter{
  set value(value: any) {
    this._value = value;
  }
  get value(): any {
    return this._value;
  }
  private _value: any;
}

/** We dont want to create a service just for this one, so we'll use a helper class to handle the data changes */
export const CHILD_UPGRADES = new InjectionToken('childUpgrades', {
  providedIn: 'root',
  factory: () => new Setter()
});

class PortalProduct {
  ProductId: number;
  ProductType: number;
  DisplayName: string;
  Description: string;
  EcashPrice: number;
  PointPrice: number;
  MerchantPrice: number;
  ProductPeriod: number;
  ProductPeriodType: ProductIntervalPeriodType;
  IntervalPeriod: number;
  IntervalPeriodType: ProductIntervalPeriodType;
  Status: number;
  PrimaryImage: string;
  SecondaryImage: string;

  constructor(o?: any) {
    o = o || {};
    this.ProductId = o.ProductId || 0;
    this.ProductType = o.ProductType || 0;
    this.DisplayName = o.DisplayName || '';
    this.Description = o.Description || '';
    this.EcashPrice = o.EcashPrice || 0;
    this.PointPrice = o.PointPrice || 0;
    this.MerchantPrice = o.MerchantPrice || 0;
    this.ProductPeriod = o.ProductPeriod || 0;
    this.ProductPeriodType = o.ProductPeriodType || 0;
    this.IntervalPeriod = o.IntervalPeriod || 0;
    this.IntervalPeriodType = o.IntervalPeriodType || 0;
    this.Status = o.Status || 0;
    this.PrimaryImage = o.PrimaryImage || '';
    this.SecondaryImage = o.SecondaryImage || '';
  }
}

class ChildUpgrade {
  Id: number;
  MemberId: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: Date | string;
  Product: PortalProduct;

  constructor(o?: any) {
    o = o || {};
    this.Id = o.Id || 0;
    this.MemberId = o.MemberId || '';
    this.FirstName = o.Firstname || o.FirstName || '';
    this.LastName = o.Lastname || o.LastName || '';
    this.DateOfBirth = o.DateOfBirth || '';
    this.Product = o.Product || new PortalProduct();
  }
}

@Component({
  selector: 'fgb-child-upgrade-options',
  templateUrl: './child-upgrade-options.component.html',
  styleUrl: './child-upgrade-options.component.scss'
})
export class ChildUpgradeOptionsComponent implements OnInit {
  @Input() children: any;
  @Input() membershipOptions: any;
  childUpgrades: WritableSignal<ChildUpgrade[]> = signal([]);
  showUpgradeOptions = false;
  selectedId: number;

  constructor(@Inject(CHILD_UPGRADES) private childrenUpgrades: any,
              private loggingService: LoggingService, 
              private basketService: BasketService,
              private router: Router) { 
  }

  ngOnInit() {

    if( this.children.upgradable?.length === 0 ){
      this.loggingService.error('No upgradable children found. Redirecting to guarduan page.');
      this.router.navigate(['/guardians']);
      return;
    }

    this.childUpgrades.set(this.childrenUpgrades.value || this.children.upgradable.map((child: any) => new ChildUpgrade(child)));
  }

  updateChild(product: PortalProduct) {
    this.showUpgradeOptions = false;
    this.childUpgrades.update((children) => {
      const index = children.findIndex((c) => c.Id === this.selectedId);
      if (index !== -1) {
        children[index] = new ChildUpgrade({ ...children[index], Product: new PortalProduct(product) });
      }
      return children;
    });
    this.childrenUpgrades.value = this.childUpgrades();
  }

  showOptions(id: number) {
    this.showUpgradeOptions = true;
    this.selectedId = id;
  }

  checkout() : void{
    const checkoutItems = this.childUpgrades().filter(c => c.Product.ProductId !== 0);
    this.basketService.addPortalProductToBasket(...checkoutItems.map(c => new PortalProductUpgradeChildBasketItem(c)));
    this.router.navigate(['/child/checkout']);
  }

  get hasSelected(): boolean{
    return this.childUpgrades().some((c) => c.Product.ProductId !== 0);
  }

}
