import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Survey, SurveyService, SurveyType } from '@fgb/core';
import * as moment from 'moment';
import { SurveyDisplayStatus } from '../survey-item.component';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-item-poll',
  templateUrl: './survey-item-poll.component.html',
  styleUrls: ['./survey-item-poll.component.scss', '../../survey.scss'],
})
export class SurveyItemPollComponent implements OnInit, OnChanges {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  @Input() status: SurveyDisplayStatus;
  displayStatus = SurveyDisplayStatus;
  surveyType = SurveyType;
  pointsWon: number;
  comingSoon: boolean;
  utcEndDate: string;
  utcStartDate: string;
  utcRequestDate: string;
  populations = Populations;

  constructor(private surveyService: SurveyService, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.pointsWon = this.calculatePointsWon();
    if (this.survey.ComingSoon && moment.utc(this.survey.StartDate) > moment.utc()) {
      this.comingSoon = true;
      this.utcStartDate = moment.utc(this.survey.StartDate).toISOString();
    } else if (this.survey.SeparateEntryWindow && moment.utc(this.survey.EntryStartDate) > moment.utc()) {
      this.comingSoon = true;
      this.utcStartDate = moment.utc(this.survey.EntryStartDate).toISOString();
    }
  }

  ngOnChanges() {
    this.pointsWon = this.calculatePointsWon();
  }

  private calculatePointsWon() {
    if (!this.survey.HasBeenAnsweredByUser) {
      return 0;
    }

    let selectedAnswers = this.survey.SurveyAnswers.filter((sa) => sa.UserSelected);
    if (selectedAnswers && selectedAnswers.length > 0) {
      return selectedAnswers[0].PointValue / 100;
    }
    return 0;
  }

  calculateEntryEnd() {
    if (!this.survey.HasBeenAnsweredByUser) {
      this.removeSurvey(this.survey.SurveyId);
    }
  }

  private removeSurvey(surveyId: number) {
    this.surveyService.removeSurvey(surveyId);
    this.cdRef.detectChanges();
  }
}
