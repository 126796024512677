<div class="d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a class="nav-item" [routerLink]="['/games/quiz']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <div class="icon material-icons" aria-hidden="true">live_help</div>
      <label>{{ 'games.nav.trivia' | translate }}</label>
    </a>
    <a class="nav-item" [routerLink]="['/games/polls']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <div class="icon material-icons" aria-hidden="true">assessment</div>
      <label>{{ 'games.nav.polls' | translate }}</label>
    </a>
    <ng-container *notInPopulation="[populations.Kids]">
      <a
        class="nav-item reward-nav-item"
        [routerLink]="['/games/predictor']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.predictor.screenreader' | translate : { fgbdefault: 'Move to Predictor page' } }}"
      >
        <div class="icon material-icons" aria-hidden="true">flaky</div>
        <label>{{ 'games.nav.predictor' | translate }}</label>
      </a>
    </ng-container>
  </ul>
</div>
