import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyType } from '@fgb/core';
import { SurveyDisplayStatus } from '../survey-item.component';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-item-quiz',
  templateUrl: './survey-item-quiz.component.html',
  styleUrls: ['./survey-item-quiz.component.scss', '../../survey.scss'],
})
export class SurveyItemQuizComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  @Input() status: SurveyDisplayStatus;
  surveyType = SurveyType;
  displayStatus = SurveyDisplayStatus;
  populations = Populations;

  constructor() {}

  ngOnInit() {
  }
}
