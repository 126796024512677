import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopupComponent } from './components/topup/topup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AutotopupComponent } from './components/autotopup/autotopup.component';
import { TopUpTicketComponent } from './components/top-up-ticket/top-up-ticket.component';
import { EcashMemberCardComponent } from './components/ecash-member-card/ecash-member-card.component';
import { UserModule } from '../user/user.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NonPartnerBonusTransferComponent } from './components/non-partner-bonus-transfer/non-partner-bonus-transfer.component';
import { PartnerBonusTransferComponent } from './components/partner-bonus-transfer/partner-bonus-transfer.component';
import { EcashDescriptionComponent } from './components/ecash-description/ecash-description.component';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';

@NgModule({
  declarations: [
    AutotopupComponent,
    TopupComponent,
    TopUpTicketComponent,
    EcashMemberCardComponent,
    NonPartnerBonusTransferComponent,
    PartnerBonusTransferComponent,
    EcashDescriptionComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    UserModule,
    CarouselModule,
    InlineSVGModule.forRoot(),
    FGBSharedModule,
  ],
  exports: [
    AutotopupComponent,
    TopupComponent,
    TopUpTicketComponent,
    EcashMemberCardComponent,
    NonPartnerBonusTransferComponent,
    PartnerBonusTransferComponent,
    EcashDescriptionComponent,
  ],
})
export class EcashModule {}
