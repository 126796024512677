<div class="survey-header d-flex flex-column p-3 justify-content-around text-white bg-primary">
  <div class="score-predictor-header">
    <div class="score-predictor-header-title d-flex justify-content-between align-items-center">
      
    <div class="d-flex">
      <div class="score-predictor-title">{{ 'score.predictor.header.title' | translate }}</div>
    </div>

    <div class="score-sponsor-image d-lg-flex align-items-center">
      <ng-container *ngIf="scorePredictor.Sponsor && scorePredictor.Sponsor.SponsorImage">
        <label class="d-none d-lg-block fw-normal font-size-8 me-lg-2 mb-0">{{ 'score.predictor.header.presented.by' | translate }}</label>
        <a class="sponsor-logo-btn" href="{{ scorePredictor.Sponsor.SponsorLink }}" target="_blank" [class.disabled]="!scorePredictor.Sponsor.SponsorLink">
          <img
            src="{{ scorePredictor.Sponsor.SponsorImage | contentImage: '6' }}"
            draggable="false"
            alt="Click to access {{ scorePredictor.Sponsor.SponsorText }} website"
          />
        </a>
      </ng-container>
    </div>
    </div>
    <p class="font-size-12 score-predictor-text">{{ 'score.predictor.header.text' | translate }}</p>
  </div>
</div>
