<div class="banner" role="banner"></div>

<div class="container">
  <div class="pt-4">
    <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start font-size-14 mb-3">
    {{ 'terms.not.available' | translate }}
  </p>
</ng-template>
