import { Component, OnInit } from '@angular/core';
import { SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { SurveyType } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss'],
})
export class QuizPageComponent implements OnInit {
  quizzes$: Observable<Survey[]>;
  populations = Populations;
  screenType = ScreenType;

  constructor(private surveyQuery: SurveyQuery) {}

  ngOnInit() {
    this.quizzes$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Trivia);
  }
}
