import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ChildQuery, ChildService } from '@fgb/core';
import { map, Observable, switchMap } from 'rxjs';

export const childResolver: ResolveFn<Observable<any>> = (route, state) => {
  const childService = inject(ChildService);
  const childQuery = inject(ChildQuery);

  return childService.fetchChildren().pipe(
    switchMap(() => childQuery.getChildrenInfo().pipe(
    map((children) => {
      return {
        data: children.data,
        free: children.free,
        empty: children.empty,
        limit: children.limit,
        upgradable: children.data.filter(child => child.AccountPaymentState === 0),
        nonUpgradable: children.data.filter(child => child.AccountPaymentState === 1),
      }
    })
  )));
};
