<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="'/rewards/marketplace'"
    [marketplaceItemGroup]="marketplaceItemGroup"
  ></fgb-points-summary-bar>
</div>

<div class="container" *notInPopulation="[populations.Partial]">
  <div
    class="text-danger reward-back-btn pt-3 cursor-pointer d-none d-lg-flex w-20 align-items-center"
    [routerLink]="'/rewards/marketplace'"
  >
    <i class="icon material-icons">chevron_left</i>
    <ng-container *notInPopulation="[populations.Kids]">
      <span class="font-size-12 fw-bold">{{ 'back.to.rewards' | translate }}</span>
    </ng-container>
    <ng-container *inPopulation="[populations.Kids]">
      <span class="font-size-12 fw-bold">{{ 'back.to.rewards.kids' | translate }}</span>
    </ng-container>
  </div>
</div>

<div class="container" *inPopulation="[populations.Partial]">
  <div
    class="text-danger reward-back-btn pt-3 cursor-pointer d-none d-lg-flex w-20 align-items-center"
    [routerLink]="'/rewards/events'"
  >
    <i class="icon material-icons">chevron_left</i>
    <span class="font-size-12 fw-bold">{{ 'back.to.rewards.events' | translate }}</span>
  </div>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
