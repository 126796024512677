import { Component, Input, OnInit } from '@angular/core';
import { PartnerQuery, Partner, PartnerService, PartnerPendingQuery, PartnerPendingService, PartnerPending } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnInit {
  @Input() noResultPlaceholder = '';
  @Input() showDeleteButton: boolean = true;
  partners$: Observable<Partner[]>;
  pendingPartners$: Observable<PartnerPending[]>;

  constructor(
    private partnerQuery: PartnerQuery,
    private partnerService: PartnerService,
    private pendingQuery: PartnerPendingQuery,
    private pendingService: PartnerPendingService
  ) {}

  ngOnInit() {
    this.partners$ = this.partnerQuery.selectAll();
    this.pendingPartners$ = this.pendingQuery.selectAll();
  }

  delete(id: number) {
    firstValueFrom(this.partnerService.remove(id));
  }

  deletePending(ticketKey: string) {
    firstValueFrom(this.pendingService.remove(ticketKey));
  }
}
