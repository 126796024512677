<nav id="navbar" class="navbar navbar-dark navbar-expand-lg d-none d-lg-flex">
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <div id="brand-logo"></div>
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav me-auto" #greedyNav>
        <ng-container *inPopulation="[populations.Orange, populations.Partner, populations.Kids]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/marketplace']" routerLinkActive="router-link-active">
              {{ 'nav.marketplace' | translate }}
            </a>
          </li>
        </ng-container>
        <ng-container *inPopulation="[populations.Partial]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/events']" routerLinkActive="router-link-active">
              {{ 'nav.experiences' | translate }}
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/wallet']" routerLinkActive="router-link-active">{{
              'nav.wallet' | translate
            }}</a>
          </li>

          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/guardians']" routerLinkActive="router-link-active">{{
                'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' }
              }}</a>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *inPopulation="[populations.Partner]">
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/guardians']" routerLinkActive="router-link-active">{{
                'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' }
              }}</a>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *notInPopulation="[populations.Default]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/challenges']" routerLinkActive="router-link-active">{{
              'nav.challenges' | translate
            }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/games']" routerLinkActive="router-link-active">{{
              'nav.games' | translate
            }}</a>
          </li>
        </ng-container>

        <ng-container *inPopulation="[populations.Default]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/wallet']" routerLinkActive="router-link-active">{{
              'nav.wallet' | translate
            }}</a>
          </li>

          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/guardians']" routerLinkActive="router-link-active">{{
                'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' }
              }}</a>
            </li>
          </ng-container>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/challenges']" routerLinkActive="router-link-active">{{
              'nav.challenges' | translate
            }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/games']" routerLinkActive="router-link-active">{{
              'nav.games' | translate
            }}</a>
          </li>
        </ng-container>

        <ng-container *inPopulation="[populations.Kids]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/worksheet']" routerLinkActive="router-link-active">{{
              'nav.worksheets' | translate : { fgbdefault: 'Activities' }
            }}</a>
          </li>
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.gallery'">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/gallery']" routerLinkActive="router-link-active">{{
                'nav.gallery' | translate : { fgbdefault: 'Gallery' }
              }}</a>
            </li>
          </ng-container>
        </ng-container>

        <ng-container *inPopulation="[populations.Orange]">
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/guardians']" routerLinkActive="router-link-active">{{
                'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' }
              }}</a>
            </li>
          </ng-container>
        </ng-container>

        <li class="nav-item active static-nav-item nav-more" focusTab #moreBtn>
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a
              class="nav-link mx-1"
              ngbDropdownToggle
              focusTabBtn
              attr.aria-label="{{ 'nav.more.screenreader' | translate : { fgbdefault: 'Click for more pages' } }}"
            >
              <span>{{ 'nav.more' | translate }}</span>
            </a>
            <ul ngbDropdownMenu class="shadow" (click)="closeDropDown(ddMore)" #greedyDropdown>
              <li class="nav-item active">
                <a
                  class="nav-link mx-1"
                  [routerLink]="['/faq']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.faq.screenreader' | translate : { fgbdefault: 'Move to faq page' } }}"
                  >{{ 'nav.faq' | translate }}</a
                >
              </li>
              <ng-container *notInPopulation="[populations.Kids]">
                <li class="nav-item active">
                  <a
                    class="nav-link mx-1"
                    [routerLink]="['/contact']"
                    routerLinkActive="router-link-active"
                    attr.aria-label="{{ 'nav.contact.us.screenreader' | translate : { fgbdefault: 'Move to contact us page' } }}"
                    >{{ 'nav.contact.us' | translate }}</a
                  >
                </li>
              </ng-container>
              <li class="nav-item active">
                <a
                  class="nav-link mx-1"
                  [routerLink]="['/terms']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.terms.screenreader' | translate : { fgbdefault: 'Move to terms page' } }}"
                  >{{ 'nav.terms' | translate }}</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <ng-container *inPopulation="[populations.Orange, populations.Partner]">
        <div class="balance font-size-14 text-white">
          <div class="d-flex align-items-center" *ngIf="loyaltyPointsCard$">
            <span
              class="icon material-icons currency-icons pe-1"
              role="img"
              attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
              >emoji_events</span
            >
            <span class="fw-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
          </div>
        </div>
        <div class="font-size-14 text-white ps-0">
          <div class="d-flex align-items-center" *ngIf="combinedCard$">
            <span
              class="icon material-symbols-outlined currency-icons me-2"
              role="img"
              attr.aria-label="{{ 'nav.ecash.screenreader' | translate }}"
              >credit_card</span
            >
            <span class="fw-bold">{{ combinedCard$ | async | FortressCurrencyPipe : 'USD' }}</span>
          </div>
        </div>
      </ng-container>
      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="notification-wrapper mx-4">
        <div class="vertical-separator"></div>
        <!--Notifications-->
        <fgb-notification-button></fgb-notification-button>
        <div class="vertical-separator"></div>
      </div>
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn text-white d-flex align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          id="account-dropdown"
          class="shadow text-center dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <ng-container *notInPopulation="[populations.Kids]">
              <a
                [routerLink]="['/account']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center account-btn text-black py-4 px-2 font-size-14 nav-item-fixed-height rounded-top ps-0"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
                <div class="dropdown-link text-start">
                  <div class="mt-2 fw-bold">{{ 'nav.dropdown.account' | translate : { fgbdefault: 'Account' } }}</div>
                  <div class="nav-bar-little-text text-start" *ngIf="card$ | async as memberCard">
                    <div class="nav-bar-little-text" *inPopulation="[populations.Orange]">
                      {{ 'membercard.productcode.orange' | translate : { fgbdefault: 'Season' } }} -
                      {{ memberCard.ExternalRefNumber }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[populations.Default]">
                      {{ 'membercard.productcode.fan' | translate : { fgbdefault: 'Ducks Fan' } }} -
                      {{ memberCard.ExternalRefNumber }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[populations.Partial]">
                      {{ 'membercard.productcode.partial' | translate : { fgbdefault: 'Plan Holder' } }} -
                      {{ memberCard.ExternalRefNumber }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[populations.Partner]">
                      {{ 'membercard.productcode.partner' | translate : { fgbdefault: 'Partner' } }} -
                      {{ memberCard.ExternalRefNumber }}
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
            <ng-container *inPopulation="[populations.Kids]">
              <a
                class="d-flex align-items-center account-btn text-black py-4 px-2 font-size-14 nav-item-fixed-height rounded-top ps-0"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
                <div class="dropdown-link text-start">
                  <div class="mt-2 fw-bold">{{ 'nav.dropdown.account' | translate : { fgbdefault: 'Account' } }}</div>
                  <div class="nav-bar-little-text text-start" *ngIf="card$ | async as memberCard">
                    <div class="nav-bar-little-text">
                      {{ 'nav.dropdown.kids' | translate : { fgbdefault: 'Wild Wingers' } }} -
                      {{ memberCard.ExternalRefNumber }}
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>
          <div>
            <hr class="text-muted my-0" />
          </div>
          <div class="text-black">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              class="d-flex align-items-center text-black py-4 px-2 nav-item-fixed-height ps-0"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
              <div class="dropdown-link text-start">
                <div class="font-size-14 fw-bold">{{ 'nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <div>
            <hr class="text-muted my-0" />
          </div>
          <ng-container *notInPopulation="[populations.Kids]">
            <div class="text-primary">
              <a
                href="https://am.ticketmaster.com/ducks/"
                target="_blank"
                class="d-flex align-items-center py-4 px-2 nav-item-fixed-height ticketing ps-0"
                ngbDropdownItem
              >
                <img src="assets/images/logos/Ducks.svg" alt="" draggable="false" width="30" height="30" />
                <div class="dropdown-link ticketing-link text-start ms-1">
                  <div class="font-size-14 fw-bold">{{ 'nav.ticketing' | translate }}</div>
                </div>
              </a>
            </div>
          </ng-container>
          <div>
            <hr class="text-muted my-0" />
          </div>
          <div>
            <ng-container *notInPopulation="[populations.Kids]">
              <button
                class="logout-btn d-flex align-items-center py-4 px-2 nav-item-fixed-height rounded-bottom ps-0"
                (click)="logout()"
                ngbDropdownItem
                attr.aria-label="{{ 'nav.log.out.screenreader' | translate }}"
              >
                <span class="icon material-icons material-dropdown-icon me-2 text-danger" aria-hidden="true"
                  >power_settings_new</span
                >
                <div class="dropdown-link text-start">
                  <div class="text-danger logout-btn py-1 font-size-14 fw-bold" ngbDropdownItem (click)="logout()">
                    {{ 'nav.log.out' | translate }}
                  </div>
                </div>
              </button>
            </ng-container>
            <ng-container *inPopulation="[populations.Kids]">
              <button
                class="back-to-guardian-btn d-flex align-items-center py-4 px-2 nav-item-fixed-height rounded-bottom mb-2 ps-0"
                (click)="switchToGuardian()"
                ngbDropdownItem
                attr.aria-label="{{ 'nav.back.to.guardian.screenreader' | translate }}"
              >
                <i class="icon material-icons material-dropdown-icon me-2 text-primary" aria-hidden="true">logout</i>
                <div class="dropdown-link text-start">
                  <div class="text-primary logout-btn py-1 font-size-14 fw-bold">
                    {{ 'nav.back.to.guardian' | translate : { fgbdefault: 'Back to Guardian' } }}
                  </div>
                </div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
