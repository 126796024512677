import { Component, OnInit } from '@angular/core';
import { TMAuthService } from '@fgb/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-tm-user-login',
  templateUrl: './tm-user-login.component.html',
  styleUrls: ['./tm-user-login.component.scss'],
})
export class TmUserLoginComponent implements OnInit {
  returnUrl: string;
  errorService: any;

  constructor(private route: ActivatedRoute, private tmService: TMAuthService) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  loginTM() {
    this.tmService.loginRedirect();
  }
}
