import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BalanceQuery,
  BalanceService,
  config,
  ErrorStateService,
  MemberQuery,
  PointsTransferService,
  RenewalResponse,
} from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-non-partner-bonus-transfer',
  templateUrl: './non-partner-bonus-transfer.component.html',
  styleUrls: ['./non-partner-bonus-transfer.component.scss'],
})
export class NonPartnerBonusTransferComponent implements OnInit {
  transferValue = 1;
  transferStatus: boolean = false;
  @ViewChild('successTransfer', { static: true }) successModalRef: ElementRef;
  @ViewChild('failedTransfer', { static: true }) failedModalRef: ElementRef;
  renewalTransferForm: FormGroup;
  currentBalance: number;
  isLoading: boolean = false;
  minTransfer = 1;

  constructor(
    private memberQuery: MemberQuery,
    private pointTransferService: PointsTransferService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private balanceQuery: BalanceQuery,
    private errorService: ErrorStateService,
    private balanceService: BalanceService
  ) {}

  ngOnInit() {
    this.balanceQuery
      .selectPurse(config.purseConfig.renewalGift)
      .subscribe((bal) => {
        this.currentBalance = bal ?? 0;
      })
      .unsubscribe();

    this.renewalTransferForm = this.formBuilder.group({
      TargetMember: ['', [Validators.required]],
    });
  }

  // call to transfer bonus credit to given Member ID
  transferBonusCredit(bonusAmount: number) {
    this.isLoading = true;
    let sourceMember = this.memberQuery.getUserContext();
    let targetMember = this.renewalTransferForm.controls.TargetMember.value;
    let amountInPence = bonusAmount * 100;
    firstValueFrom(this.pointTransferService.renewalBonusTransfer(sourceMember.memberId, targetMember, amountInPence))
      .then((response) => {
        let renewalResponse = response as RenewalResponse;
        if (!!renewalResponse.ErrorNumber) {
          this.errorService.addError(renewalResponse.ErrorDescription);
          this.transferStatus = false;
          this.openTransferStatusModal();
        } else if (!!renewalResponse.Tickets.filter((ticket) => !!ticket.ErrorNumber).length) {
          this.errorService.addError(renewalResponse.Tickets.filter((ticket) => !!ticket.ErrorNumber)[0].ErrorDesc);
          this.transferStatus = false;
          this.openTransferStatusModal();
        } else {
          this.transferStatus = true;
          this.openTransferStatusModal();
          this.balanceService.debitBalance(config.purseConfig.renewalGift, bonusAmount);
        }
        this.isLoading = false;
        this.transferValue = 1;
      })
      .catch((err) => {
        this.transferStatus = false;
        this.openTransferStatusModal();
      });
  }

  openTransferStatusModal() {
    this.renewalTransferForm.reset();
    if (this.transferStatus) {
      this.modalService.open(this.successModalRef, { centered: true });
    } else {
      this.modalService.open(this.failedModalRef, { centered: true });
    }
  }
}
