<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/marketplace'" [backButtonTitle]="'Auctions'"></fgb-points-summary-bar>
</div>

<!-- Back Button -->
<div class="container">
  <div
    class="text-danger reward-back-btn pt-3 cursor-pointer d-none d-lg-flex w-20 align-items-center"
    [routerLink]="'/rewards/marketplace'"
  >
    <i class="icon material-icons">chevron_left</i>
    <ng-container *notInPopulation="[populations.Kids]">
      <span class="font-size-12 fw-bold">{{ 'back.to.rewards' | translate }}</span>
    </ng-container>
    <ng-container *inPopulation="[populations.Kids]">
      <span class="font-size-12 fw-bold">{{ 'back.to.rewards.kids' | translate }}</span>
    </ng-container>
  </div>
</div>

<!-- Auction Details -->
<fgbcl-auction-details [auctionId]="id"></fgbcl-auction-details>
