<div class="survey-container overflow-hidden rounded border quiz">
  <div class="survey-header">
    <div class="image-container" [class.no-image]="!survey.ImageURL">
      <ng-container *ngIf="survey.ImageURL; else noImage">
        <img src="{{ survey.ImageURL | contentImage : '25' }}" alt="{{ survey.ImageURL }}" />
        <div class="sponsor-link" *ngIf="survey.Sponsor && survey.Sponsor.SponsorImage">
          <label class="sponsor-label d-lg-block fw-normal font-size-10 text-white me-lg-2 mb-0">PRESENTED BY</label>
          <a id="sponsor-link" href="{{ survey.Sponsor.SponsorLink }}" target="_blank">
            <img src="{{ survey.Sponsor.SponsorImage | contentImage : '6' }}" draggable="false" alt="" />
            <label class="accessibility-only" for="sponsor-link">Go to {{ survey.Sponsor.SponsorText }} website"</label>
          </a>
        </div>
      </ng-container>
      <ng-template #noImage>
        <h3 class="quiz-title text-uppercase">{{ 'quiz.title' | translate : { fgbdefault: 'Trivia' } }}</h3>
        <ng-container *inPopulation="[populations.Orange, populations.Partner]">
          <p class="survey-instructions">
            {{ 'quiz.instructions' | translate : { fgbdefault: 'Pick Your Answer and Earn Points' } }}
          </p>
        </ng-container>
        <ng-container *inPopulation="[populations.Kids, populations.Partial, populations.Default]">
          <p class="survey-instructions">{{ 'quiz.no.points.instructions' | translate : { fgbdefault: 'Pick Your Answer' } }}</p>
        </ng-container>
      </ng-template>
    </div>
    <div class="survey-info">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="icon material-icons" aria-hidden="true">live_help</div>
        </div>
        <ng-container *ngIf="status !== displayStatus.ComingSoon && !survey.HasBeenAnsweredByUser">
          <div class="text-end">
            <span class="icon icon-clock font-size-14 me-1 fw-bold"></span>
            <!-- <fgbcl-countdown [endDateTime]="survey.EndDate" [isUTC]="true" class="font-size-16 fw-bold"></fgbcl-countdown> -->
          </div>
        </ng-container>
      </div>
      <div
        class="survey-title font-size-14 line-height-14"
        [ngClass]="{
          'with-image': !!survey.ImageURL
        }"
      >
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
    </div>
  </div>

  <ng-template #comingSoon>
    <div class="coming-soon d-flex align-items-center justify-content-center">
      <p class="text-center fw-bold text-primary m-0">
        {{ 'survey.item.quiz.coming.soon.text' | translate : { fgbdefault: 'Coming soon' } }}
      </p>
    </div>
  </ng-template>

  <ng-container *ngIf="!survey.ComingSoon || status !== displayStatus.ComingSoon; else comingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-quiz [survey]="survey"></fgb-survey-item-answer-completed-quiz>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>
