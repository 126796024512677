<div class="child-upgrade-options">
  <div class="child-upgrade-header">
    <h5>{{ 'child.upgrade.options.title' | translate }}</h5>
    <p class="subtitle font-size-12">{{ 'child.upgrade.options.instruction' | translate }}</p>
  </div>
  @if( showUpgradeOptions ){
  <fgb-membership-options-list
    [membershipOptions]="membershipOptions"
    (selectionChange)="updateChild($event)"
  ></fgb-membership-options-list>
  } @else {
  <div class="child-upgrade-content">
    <div class="child-upgrade-container" *ngFor="let child of childUpgrades(); let i = index">
      <div class="child-details-container">
        <div class="child-details">
          <h5 class="child-title text-primary mb-0">{{ 'child.upgrade.detail.title' | translate }} {{ i + 1 }}</h5>
          <div class="child-info">
            <label class="child-name me-3">{{ child.FirstName }} {{ child.LastName }}</label>
            <span class="dob font-size-12">{{ child.DateOfBirth | UtcToLocaltimePipe | date : 'mediumDate' }}</span>
          </div>
        </div>
        <div class="child-upgrade-action" *ngIf="!child.Product.ProductId">
          <button class="btn btn-primary" (click)="showOptions(child.Id)">
            <span class="text-white fw-bold">{{ 'registration.membership.option.select.membership.button' | translate }}</span>
          </button>
        </div>
      </div>

      <ng-container *ngIf="child.Product.ProductId">
        <ng-container *ngTemplateOutlet="selectedMembership; context: { $implicit: child.Product, child: child }"></ng-container>
      </ng-container>
    </div>
  </div>
  }
  <div class="child-upgrade-footer" *ngIf="!showUpgradeOptions">
    <button class="btn text-danger fw-bold" [routerLink]="'/guardians'">
      {{ 'child.upgrade.options.back.button' | translate }}
    </button>
    <button class="btn btn-success fw-bold" (click)="checkout()" *ngIf="hasSelected">
      {{ 'child.upgrade.options.next.button' | translate }}
    </button>
  </div>
</div>

<ng-template #selectedMembership let-membership let-child="child">
  <div class="selected-membership">
    <div class="selected-membership-details">
      <img alt="" src="{{ membership.PrimaryImage | contentImage : '1' }}" class="membership-logo" />
      <div class="membership-details">
        <label class="membership-product-name">{{ membership.DisplayName }}</label>
        <div class="price">
          <label>{{ 'registration.membership.options.price.label' | translate }}</label>
          <span class="price-value">{{ membership.MerchantPrice / 100 | FortressCurrencyPipe }}</span>
        </div>
      </div>
    </div>
    <button class="btn change-membership-btn" (click)="showOptions(child.Id)">
      {{ 'registration.membership.options.change.membership' | translate }}
    </button>
  </div>
</ng-template>
