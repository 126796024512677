import { Component, HostListener, OnInit, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import {
  config,
  CompanyQuery,
  Company,
  CompanyOfferModel,
  CompanyOfferQuery,
  AnnouncementService,
  WorksheetService,
  WorksheetQuery,
  Worksheet,
  MarketplaceItemExtended,
  MarketplaceExtendedQuery,
  ProcessProviderId,
  MarketplaceService,
} from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';
enum RewardWidth {
  Destop = 302,
  Tablet = 194,
  Mobile = 165,
}

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  companies$: Observable<Company[]>;
  worksheets$: Observable<Worksheet[]>;
  featuredOffers$: Observable<CompanyOfferModel[]>;
  eventItems$: Observable<MarketplaceItemExtended[] | undefined>;
  martketplaceItems$: Observable<MarketplaceItemExtended[] | undefined>;
  populations = Populations;
  screenType = ScreenType;
  rewardsWidth: number | string;
  isDragging: boolean = false;

  customOptions: WritableSignal<OwlOptions> = signal({
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      540: { items: 2 },
      740: { items: 3 },
    },
  });

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.setRewardsWidth(event?.target.screen.width);
  }

  constructor(
    private companyQuery: CompanyQuery,
    private companyOfferQuery: CompanyOfferQuery,
    private router: Router,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private announcementService: AnnouncementService,
    private worksheetQuery: WorksheetQuery,
    private worksheetService: WorksheetService,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    this.marketplaceService.fetchMarketplaceItems().toPromise();
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    this.companies$ = this.companyQuery.selectFavouriteCompanies().pipe(
      map((companies) => {
        return companies;
      })
    );
    firstValueFrom(this.announcementService.fetchAnnouncements());
    this.fetchFavouriteEventItems();
    this.fetchFavouriteMarketplaceItems();

    firstValueFrom(this.worksheetService.fetchWorksheetByRoute(''));
    this.worksheets$ = this.worksheetQuery.getWorksheetByRoute('');
    this.setRewardsWidth(window.screen.width);
  }

  fetchFavouriteEventItems() {
    let ProcessProviderId: ProcessProviderId[] = [3];
    this.eventItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItemsOfType(...ProcessProviderId).pipe(
      map((items) => {
        return items?.filter((item) => item.Favourite);
      }),
      tap((items) => (items && items?.length < 3 ? this.setLoop(false) : this.setLoop(true)))
    );
  }

  fetchFavouriteMarketplaceItems() {
    let ProcessProviderId: ProcessProviderId[] = [0, 1, 2, 3];
    this.martketplaceItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItemsOfType(...ProcessProviderId).pipe(
      map((items) => {
        return items?.filter((item) => item.Favourite);
      }),
      tap((items) => (items && items?.length < 3 ? this.setLoop(false) : this.setLoop(true)))
    );
  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }

  setRewardsWidth(screenSize: number) {
    if (screenSize >= 992) this.rewardsWidth = RewardWidth.Destop;
    if (screenSize < 992 && screenSize >= 425) this.rewardsWidth = RewardWidth.Tablet;
    if (screenSize < 425) this.rewardsWidth = RewardWidth.Mobile;
  }

  setLoop(value: boolean) {
    this.customOptions.update((state) => ({ ...state, loop: value }));
  }
}
