import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {
  PartnerQuery,
  PartnerPendingQuery,
  MemberDetails,
  MemberQuery,
  PartnerService,
  Partner,
  PartnerPending,
} from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPartnerModalComponent } from 'src/app/pages/partner-page/modals/add-partner-modal/add-partner-modal.component';
import { AcceptPartnerModalComponent } from 'src/app/pages/partner-page/modals/accept-partner-modal/accept-partner-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fgb-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss'],
})
export class PartnerPageComponent implements OnInit {
  // showAddForm: boolean;
  // showAcceptForm: boolean;
  partnersCount: number;
  memberDetails$: Observable<MemberDetails | undefined>;
  partnerLimit$: Observable<number>;
  partners: Partner[];
  pendingPartners: PartnerPending[];
  currentPartnerCount: number;
  populations = Populations;
  private _destroyRef = inject(DestroyRef);

  constructor(
    private partnerQuery: PartnerQuery,
    private pendingQuery: PartnerPendingQuery,
    public memberQuery: MemberQuery,
    private partnerService: PartnerService,
    private modalService: NgbModal
  ) {}


  ngOnInit() {
    this.partnerLimit$ = this.partnerService.getPartnerLimit();

    combineLatest([this.partnerQuery.selectAll(), this.pendingQuery.selectAll()])
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(([partners, pendingPartners]) => {
        this.partners = partners;
        this.pendingPartners = pendingPartners;

        if (this.partners && this.pendingPartners) {
          this.currentPartnerCount = this.partners.length + this.pendingPartners.length;
        }
      });

    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }

  // toggleAddForm() {
  //   this.showAddForm = !this.showAddForm;
  //   this.showAcceptForm = false;
  // }

  // toggleAcceptForm() {
  //   this.showAcceptForm = !this.showAcceptForm;
  //   this.showAddForm = false;
  // }

  openAddPartnerModal(): void{
    this.modalService.open(AddPartnerModalComponent, { windowClass: 'add-partner-modal', centered: true }).result.then(
      () => {},
      (reason) => {
      }
    );
  }

  openAcceptPartnerModal(): void{
    this.modalService.open(AcceptPartnerModalComponent, { windowClass: 'accept-partner-modal', centered: true }).result.then(
      () => {},
      (reason) => {
        
      });
  }

}
