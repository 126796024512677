<div class="d-block">
  <a class="company-container mb-3 rounded overflow-hidden d-block" [ngClass]="highlighted ? 'highlighted' : 'shadow'">
    <div class="company-item">
      <!--Company Image-->
      <div class="company-image d-flex align-items-start">
        <img alt="" class="w-100" src="{{ offer.ImageUrl | contentImage: '23' }}" />
      </div>
      <!--Company Description-->
      <div class="company-description-box">
        <div class="company-description text-start text-black overflow-hidden" [innerHTML]="languageContent.Description"></div>

        <div class="view-offer d-flex justify-content-end align-items-center">
          <button
            class="btn btn-primary text-white fw-bold d-flex justify-content-center align-items-center"
            (click)="navigatedToOffer()"
          >
            <span class="view-offer-text fw-bold">{{ 'view.offers.button' | translate }}</span>
          </button>
        </div>
      </div>
      <!--Company Logo-->
      <div class="company-logo-box shadow" *ngIf="currentCompany$ | async as currentCompany">
        <img
          alt="{{ currentCompany.DisplayName }}"
          src="{{ currentCompany.ImageUrl | contentImage: '7' }}"
          class="company-logo h-100"
        />
      </div>
    </div>
  </a>
</div>
