<div class="full-page bg-black panel w-100">
  <div class="overflow-hidden p-0">
    <div class="user-login-panel d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="login-banner mx-auto mb-4">
          <img src="assets/images/login-registration/ducks.svg" alt="miami-heats-logo" width="300" height="auto" />
        </div>
        <div class="col-12 user-login-panel-content bg-white mx-auto rounded-4">
          <div class="icon material-icons maintenance-icon mb-4">engineering</div>
          <h5 class="heading-text">{{ 'maintenance.page.text' | translate }}</h5>
          <p
            class="font-size-14 panel-text mlb-text"
            [innerHTML]="'maintenance.page.description' | translate | markdownTranslate"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
