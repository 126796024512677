<div class="points-summary-bar d-flex justify-content-between d-lg-none">
  <!-- Back Button -->
  <ng-container *notInPopulation="[populations.Partial]">
    <div class="text-danger cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
      <i class="material-icons">chevron_left</i>
      <ng-container *notInPopulation="[populations.Kids]">
        <span class="font-size-12 fw-bold">{{ 'back.to.rewards' | translate }}</span>
      </ng-container>
      <ng-container *inPopulation="[populations.Kids]">
        <span class="font-size-12 fw-bold">{{ 'back.to.rewards.kids' | translate }}</span>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *inPopulation="[populations.Partial]">
    <div class="text-danger cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="'/rewards/events'">
      <i class="material-icons">chevron_left</i>
      <span class="font-size-12 fw-bold">{{ 'back.to.rewards.events' | translate }}</span>
    </div>
  </ng-container>
  <!-- Points Bar -->
  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <ng-container *notInPopulation="[populations.Kids]">
      <fgb-points-summary></fgb-points-summary>
    </ng-container>
  </div>
</div>
