<div class="membership-options-list">
  <div class="membership-options">
    <div class="membership-option" *ngFor="let membershipOption of membershipOptions">
      <div class="img-container">
        <img alt="" src="{{ membershipOption.PrimaryImage | contentImage : '3' }}" class="membership-option-image" />
      </div>
      <div class="membership-detail">
        <h5>{{ membershipOption.DisplayName }}</h5>
        <span class="price-value">{{ membershipOption.MerchantPrice / 100 | FortressCurrencyPipe }}</span>
      </div>
      <div class="membership-benefits">
        <label>{{ 'registration.membership.options.membership.includes.label' | translate }}</label>
        <p [innerHtml]="membershipOption.Description | markdownTranslate"></p>
      </div>
      <button class="btn btn-primary select-membership-button" (click)="selectionChange.emit(membershipOption)">
        {{ 'registration.membership.option.select.membership.button' | translate }}
      </button>
    </div>
  </div>
</div>
