<div class="survey-answer text-center quiz" [ngClass]="hasSelectedCorrect ? 'my-lg-5 my-2' : 'my-2'">
  <div class="row">
    <div class="col-12 col-lg-6 order-2 order-lg-1">
      <div class="survey-answer-container text-answer text-white row completed">
        <div [ngClass]="hasSelectedCorrect ? 'col-12' : 'col-12'">
          <div
            class="survey-answer-item my-3"
            [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect '"
            *ngFor="let answer of selectedAnswers"
          >
            <div class="answer-text text-center px-3">
              <span>{{ answer.Title }}</span>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!hasSelectedCorrect">
          <div class="survey-answer-item bg-success correct my-3">
            <div class="answer-text text-center px-3">
              <span>{{ correctAnswer.Title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 order-1 order-lg-2">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <div class="survey-message text-success">
          <div>
            <ng-container *ngIf="pointsWon; else noPoints">
              <div class="fw-bold font-size-14 mt-4 mt-lg-0">
                {{ 'survey.completed.quiz.correct' | translate : { fgbdefault: 'Correct Answer !' } }}
              </div>
              <ng-container *notInPopulation="[populations.Partial, populations.Default, populations.Kids]">
                <div class="points-won fw-bold">
                  +{{ pointsWon }} {{ 'survey.completed.quiz.points' | translate : { fgbdefault: 'Points' } }}
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noPoints>
              <p class="fw-bold font-size-14 mt-4 mt-4 mt-lg-0">
                {{ 'survey.completed.quiz.correct' | translate : { fgbdefault: 'Correct Answer !' } }}
              </p>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="survey-message text-danger">
          <div>
            <div class="fw-bold font-size-14 mt-4 mt-4 mt-lg-0">
              {{ 'survey.completed.quiz.wrong' | translate : { fgbdefault: 'Wrong Answer !' } }}
            </div>
            <div class="points-won fw-bold">
              {{ 'survey.completed.quiz.wrong.message' | translate : { fgbdefault: 'Better luck next time' } }}
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
