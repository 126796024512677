import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, AddressService, AddressType, ErrorStateService, GiftCodeEntitlementValueType, GiftCodeModel, GiftCodeService, GiftCodeType, LoggingService, MemberProduct, PaymentService, PortalProductsService, ProviderActionKind } from '@fgb/core';
import { Router } from '@angular/router';
import { FGBValidators } from '@fgb/portal-component-library/src/lib/shared/validators';
import { firstValueFrom, take, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getBasketItemByType } from 'src/app/pages/child-page/basket.service';

@Component({
  selector: 'fgb-child-upgrade-checkout',
  templateUrl: './child-upgrade-checkout.component.html',
  styleUrl: './child-upgrade-checkout.component.scss'
})
export class ChildUpgradeCheckoutComponent implements OnInit {
  @Input() address: { addresses: Address[], billing: Address, shipping: Address };
  shippingAddress: Address;
  billingAddress: Address;
  shippingAddressForm: FormGroup;
  billingAddressForm: FormGroup;
  editShippingAddress: boolean = false;
  editBillingAddress: boolean = false;
  totalAmount: number = 0;
  totalShipping: number = 0;
  totalTax: number = 0;
  totalBasketAmount: number = 0;
  appliedGiftCodes: GiftCodeModel[] = [];
  giftCodeInput: string;
  disableApplyGiftCodesButton: boolean = false;
  paymentModal: any;
  loadingPaymentForm: boolean;
  orderCompleted: boolean = false;
  basketItems = getBasketItemByType(0);

  constructor(private router: Router, 
              private loggingService: LoggingService,
              private formBuilder: FormBuilder,
              private addressService: AddressService,
              private giftCodeService: GiftCodeService,
              private errorService: ErrorStateService,
              private portalProductService: PortalProductsService,
              private modalService: NgbModal,
              private paymentService: PaymentService,
              private _zone: NgZone) {

  }

  ngOnInit() {
    this.shippingAddress = this.address.shipping;
    this.billingAddress = this.address.billing;
    this._initForms();

    if( this.basketItems.length === 0 ){
      this.loggingService.error('No items found in the basket. Redirecting to guardians page.');
      this.router.navigate(['/guardians']);
      return;
    }

    this.calculateTotalAmount();
  }

  private _initForms(): void{
    this.shippingAddressForm = this._generateShippingAddressForm(this.shippingAddress);
    this.billingAddressForm = this._generateBillingAddressForm(this.billingAddress);
  }

  private _generateBillingAddressForm(billing: Address): UntypedFormGroup {
    return this.formBuilder.group({
      FirstName: [ billing.FirstName || '', [ Validators.required, FGBValidators.Empty ]],
      Surname: [ billing.Surname || '', [ Validators.required, FGBValidators.Empty ]],
      Street: [billing.Street || '', [Validators.required, FGBValidators.Empty]],
      Town: [billing.Town || '', [Validators.required, FGBValidators.Empty]],
      County: [billing.County || '', [Validators.required, FGBValidators.Empty]],
      Postcode: [billing.Postcode || '', [Validators.required, FGBValidators.Empty]],
      Country: [billing.Country || '', [Validators.required, FGBValidators.Empty]],
      AddressType: [AddressType.BillingAddress1],
      PortalId: [billing?.PortalId]
    });
  }

  private _generateShippingAddressForm(shipping: Address): UntypedFormGroup {
    return this.formBuilder.group({
      FirstName: [ shipping.FirstName || '', [ Validators.required, FGBValidators.Empty ]],
      Surname: [ shipping.Surname || '', [ Validators.required, FGBValidators.Empty ] ],
      Street: [shipping.Street || '', [Validators.required, FGBValidators.Empty]],
      Town: [shipping.Town || '', [Validators.required, FGBValidators.Empty]],
      County: [shipping.County || '', [Validators.required, FGBValidators.Empty]],
      Postcode: [shipping.Postcode || '', [Validators.required, FGBValidators.Empty]],
      Country: [shipping.Country || '', [Validators.required, FGBValidators.Empty]],
      AddressType: [AddressType.ShippingAddress1],
      PortalId: [shipping?.PortalId]
    });
  }

  cancelShippingForm(): void{
    this.editShippingAddress = false;
    this.shippingAddressForm = this._generateShippingAddressForm(this.shippingAddress);
  }

  cancelBillingForm(): void{
    this.editBillingAddress = false;
    this.billingAddressForm = this._generateBillingAddressForm(this.billingAddress);
  }

  updateBillingAddress() {
    this.billingAddressForm.markAllAsTouched();

    if (this.billingAddressForm.valid) {
      this.billingAddress = { ...this.billingAddress, ...this.billingAddressForm.value };

      this.editBillingAddress = false;
      this.addressService.saveAddress(this.billingAddress!);
    }
  }

  updateShippingAddress() {
    this.shippingAddressForm.markAllAsTouched();

    if (this.shippingAddressForm.valid) {
      this.shippingAddress = { ...this.shippingAddress, ...this.shippingAddressForm.value };

      this.editShippingAddress = false;
      this.addressService.saveAddress(this.shippingAddress!);
    }
  }

  showModalPayment(template: any) {
    this.errorService.clearErrors();
    this.processPayment(template);
  }

  processPayment(template?: any) {
    this.loadingPaymentForm = true;
    let memberProducts: MemberProduct[] = this.basketItems.map((item) => {
        return { MemberId: item.Child.MemberId, ProductId: item.Product.ProductId, MerchantPrice: item.Product.MerchantPrice };
      });
    firstValueFrom(this.portalProductService.purchase(memberProducts, this.billingAddress, this.appliedGiftCodes))
      .then((response) => {
        this.loadingPaymentForm = false;
        this.totalBasketAmount = response.Amount;
        if (response.ProviderActionInformation.ActionKind == ProviderActionKind.None) {
          this.orderCompleted = true;
        } else {
          if (template) {
            this.showNextModal(template, response);
          } else {
            this.paymentService.process(response, 'paymentForm', 'portalproducts/failure');
          }
        }
      })
      .catch((error) => {
        this.loadingPaymentForm = false;
      });
  }

  applyGiftCode() {
    this.errorService.clearErrors();

    if (this.disableApplyGiftCodesButton) {
      return;
    }

    if (!this.giftCodeInput) {
      this.errorService.addError('Gift Code cannot be empty');
      return;
    }

    if (!!this.appliedGiftCodes.filter((code) => code.Code === this.giftCodeInput).length) {
      this.errorService.addError('This gift code has already been applied');
      return;
    }

    if (this.totalAmount === 0) {
      this.errorService.addError('Gift code cannot be applied if the basket value is 0');
      return;
    }

    if (this.appliedGiftCodes.length > 4) {
      this.errorService.addError('Only 5 gift code can be applied.');
      return;
    }

    this.disableApplyGiftCodesButton = true;

    firstValueFrom(this.giftCodeService.validateGiftCode(this.giftCodeInput))
      .then((giftCode) => {
        if (
          giftCode.GiftCodeAssignType == GiftCodeType.Product &&
          !this.basketItems.filter(
            (item) =>
              item.Product.ProductId == giftCode.EntitlementItemId || item.Product.ProductId == giftCode.EntitlementItemId2
          ).length
        ) {
          this.errorService.addError('Gift code does not apply to any of the basket items.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (
          giftCode.EntitlementValueType == GiftCodeEntitlementValueType.Percentage &&
          !!this.appliedGiftCodes.filter((gc) => gc.EntitlementValueType == GiftCodeEntitlementValueType.Percentage).length
        ) {
          this.errorService.addError('Percentage discount can only be applied once to an order.');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (giftCode.GiftCodeStatus != 0) {
          this.errorService.addError('Gift code has been used');
          this.disableApplyGiftCodesButton = false;
          return;
        } else if (!giftCode.Active) {
          this.errorService.addError('Gift code not available.');
          this.disableApplyGiftCodesButton = false;
          return;
        }

        if (giftCode.EntitlementValueType === GiftCodeEntitlementValueType.Value) {
          this.appliedGiftCodes.unshift(giftCode);
        } else {
          this.appliedGiftCodes.push(giftCode);
        }

        this.disableApplyGiftCodesButton = false;

        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });

        this.calculateTotalAmount();
        this.giftCodeInput = '';
      })
      .catch((err) => {
        this.disableApplyGiftCodesButton = false;
      });
  }
  
  /** Calculate the new total basket price after Gift Code has been applied */
  private _calculateGiftCodeDeduction(giftCode: GiftCodeModel) {
    this.errorService.clearErrors();
    let discount = 0;

    if (giftCode.GiftCodeAssignType === GiftCodeType.Order) {
      switch (giftCode.EntitlementValueType) {
        // Value
        case GiftCodeEntitlementValueType.Value: {
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalBasketAmount;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code === giftCode.Code);
          discount = this.totalBasketAmount;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType === GiftCodeType.Shipping) {
      if (this.totalShipping === 0) {
        this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code !== giftCode.Code);
        this.errorService.addError('Gift Code cannot be applied if the shipping is already 0');
        return;
      }

      switch (giftCode.EntitlementValueType) {
        case GiftCodeEntitlementValueType.Value: {
          // Value
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalShipping;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          discount = this.totalShipping;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType == GiftCodeType.Product) {
      var numberOfTimesApplied = 0;
      this.basketItems
        .filter(
          (item) => item.Product.ProductId == giftCode.EntitlementItemId || item.Product.ProductId == giftCode.EntitlementItemId2
        )
        .forEach((item) => {
          if (numberOfTimesApplied < giftCode.EntitlementValue) {
            numberOfTimesApplied++;
            discount += item.Product.MerchantPrice;
          }
        });
    }
    this.totalBasketAmount = Math.max(0, this.totalBasketAmount - discount);
  }

 calculateTotalAmount() {
    this.totalAmount = this.basketItems.reduce((tot: number, cu: any) => {
      if (cu.Product.ProductId > 0) {
        return tot + cu.Product.MerchantPrice;
      }
      return tot;
    }, 0);

    let memberProducts: MemberProduct[] = this.basketItems
      .map((item: any) => {
        return { MemberId: item.MemberId, ProductId: item.Product.ProductId, MerchantPrice: item.Product.MerchantPrice };
      });

    if (memberProducts.length) {
      this.portalProductService
        .calculateAdditionalCharges(memberProducts, false, this.appliedGiftCodes)
        .pipe(
          tap((charges) => {
            this.totalTax = charges.TotalTax;
            this.totalShipping = charges.TotalShipping;
            this.totalBasketAmount = this.totalAmount + this.totalShipping + this.totalTax;
          })
        )
        .toPromise()
        .then(() => {
          if (this.appliedGiftCodes.length > 0) {
            this.appliedGiftCodes.forEach((x) => {
              this._calculateGiftCodeDeduction(x);
            });
          }
        });
    } else {
      this.totalTax = 0;
      this.totalShipping = 0;
      this.totalBasketAmount = 0;
    }
  }

    /** Works out the total basket price after removal of gift code */
    removeGiftCode(code: string) {
      this.errorService.clearErrors();
      let giftCode = this.appliedGiftCodes.find((x) => x.Code === code);
  
      if (giftCode) {
        this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x !== giftCode);
  
        /** reset basket values and recalculate with remaining giftcodes */
        this.calculateTotalAmount();
  
        if (this.appliedGiftCodes.length > 0) {
          this.appliedGiftCodes.forEach((x) => {
            this._calculateGiftCodeDeduction(x);
          });
        }
      }
    }

  showNextModal(template: any, response: any) {
    this.paymentModal = this.modalService.open(template, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'add-payment-details-modal',
    });
    
    this._zone.onStable.pipe(take(1)).subscribe(() => {
      this.paymentService.process(response, 'paymentdiv', 'portalproducts/failure');
    });
  }

}
