import { Component, OnInit, Input } from '@angular/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-points-summary-bar',
  templateUrl: './points-summary-bar.component.html',
  styleUrls: ['./points-summary-bar.component.scss'],
})
export class PointsSummaryBarComponent implements OnInit {
  @Input() backButtonLink: string = '/rewards';
  @Input() backButtonTitle: string;
  @Input() marketplaceItemGroup: string;
  populations = Populations;

  constructor() {}

  ngOnInit() {}
}
