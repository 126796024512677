<div class="banner" *ifScreenSize="screenType.Desktop">
  <div class="container">
    <h1>{{ 'card.linking.page.title' | translate }}</h1>
  </div>
</div>
<div>
  <div class="container my-4" *ifScreenSize="screenType.Mobile">
    <fgbcl-barcode [showCashBackToggle]="true"></fgbcl-barcode>
  </div>
  <fgb-account-nav></fgb-account-nav>
  <!--How It work section-->
  <div class="container flex-reverse">
    <div class="d-block">
      <fgbcl-card-link-earn-steps
        [cardTitle]="'payment.description.title'"
        [stepOneIcon]="'credit_card'"
        [stepTwoIcon]="'emoji_events'"
        [stepThreeIcon]="'star'"
        [stepOneText]="'payment.description.step.one'"
        [stepTwoText]="'payment.description.step.two'"
        [stepThreeText]="'payment.description.step.three'"
        [horizontalLine]="true"
        [filled]="true"
        [border]="false"
      >
      </fgbcl-card-link-earn-steps>
    </div>
    <!--My Card Section-->
    <div>
      <h2 class="card-link-add-card-title font-size-20">{{ 'card.linking.cards.title' | translate }}</h2>
      <div class="row d-flex flex-lg-row flex-column flex-column-reverse">
        <div class="col-lg-2 col-12">
          <fgbcl-card-link-button [cardLinkingSettings]="cardLinkingSettings" [countries]="countries"></fgbcl-card-link-button>
        </div>
        <div class="col-lg-10 ps-1 ms-lg-0 ms-1 col-12">
          <ng-container *ngIf="linkedCardList$ | async as cards">
            <fgbcl-card-linked-list [linkedCardList]="cards"></fgbcl-card-linked-list>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Transaction Work -->
<div class="container mt-4">
  <hr class="horizontal-line" />
  <h2 class="transaction-history-title d-flex d-flex-row align-items-center justify-content-start font-size-20">
    <span class="icon material-icons">history</span>
    {{ 'card.linking.transaction.history.title' | translate }}
  </h2>
  <fgbcl-card-linked-transactions-list  [memberId]="memberId" [dateFormat]="dateFormat"> </fgbcl-card-linked-transactions-list>
</div>
