import { assertInInjectionContext, inject, Injectable } from '@angular/core';
import { PortalProduct } from '@fgb/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum BASKET_ITEM_TYPE {
  UPGRADE_CHILD
}

export class ChildDetails{

  constructor(
    public Id: number,
    public MemberId: string,
    public FirstName: string,
    public LastName: string,
    public DateOfBirth: string ){
    }
  
}

export class PortalProductUpgradeChildBasketItem{
  Product: PortalProduct;
  Child: ChildDetails;
  type: BASKET_ITEM_TYPE;
  
  constructor(o?: any){
    o = o || {};
    this.Product = o.Product;
    this.Child = new ChildDetails(o.Id, o.MemberId, o.FirstName, o.LastName, o.DateOfBirth);
    this.type = BASKET_ITEM_TYPE.UPGRADE_CHILD;
  }
}

export type BasketItem = PortalProductUpgradeChildBasketItem;

export function getBasketItemByType(type: BASKET_ITEM_TYPE): BasketItem[]{
  assertInInjectionContext(getBasketItemByType);
  return inject(BasketService).getBasketItemByType(type);
}

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  private _basket: BehaviorSubject<BasketItem[]> = new BehaviorSubject<BasketItem[]>([]);
  basket$: Observable<BasketItem[]> = this._basket.asObservable();
  
  constructor() { }

  addPortalProductToBasket(...items: BasketItem[]): void {
    this._basket.next(items);
  }

  clearBasket(): void {
    this._basket.next([]);
  }

  getBasketItemByType(type: BASKET_ITEM_TYPE): BasketItem[] {
    return this._basket.getValue().filter(item => item.type === type);
  }

}
