import { Component } from '@angular/core';
import { AuthService, config } from '@fgb/core';
import { Router, NavigationEnd } from '@angular/router';
declare var gtag: any;
import { filter } from 'rxjs/operators';
@Component({
  selector: 'fgb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // private _destroyed$ = new Subject<void>();
  constructor(
    public authService: AuthService,
    private router: Router // private translationLoader: TranslationLoader
  ) {

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsTag;
    document.head.prepend(script);

    const navigationEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    navigationEvent.subscribe((e) => {
      let event = e as NavigationEnd;
      gtag('config', config.googleAnalyticsTag, { page_path: event.urlAfterRedirects });
    });

    /** Google maps API */
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + config.googleMapsAPIKey;
    document.head.prepend(googleMapsScript);
  }
}
