import { Component, OnInit, OnDestroy } from '@angular/core';

import { FantasyLeaderboard, FantasyLeaderboardQuery, FantasyLeaderboardService } from '@fgb/core';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { combineLatest, Subscription } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-fantasy-leaderboard',
  templateUrl: './fantasy-leaderboard.component.html',
  styleUrls: ['./fantasy-leaderboard.component.scss'],
})
export class FantasyLeaderboardComponent  implements OnInit, OnDestroy {

  yearlyLeaderboards: FantasyLeaderboard[];
  monthlyLeaderboard: any[] = [];
  fantasySub: Subscription;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    nav: true,
    dots: false,
    startPosition: new Date().getMonth(),
    navText: ['<i class="material-symbols-outlined fw-bold">chevron_left</i>', '<i class="material-symbols-outlined fw-bold">chevron_right</i>'],
    stagePadding: 0,
  };

  constructor(
    private fantasyLeaderboardQuery: FantasyLeaderboardQuery,
    private fantasyLeaderboardService: FantasyLeaderboardService
  ) {}

  ngOnInit() {
    this.fantasySub = combineLatest([
      this.fantasyLeaderboardService.fetchMonthlyLeaderboard(10),
      this.fantasyLeaderboardService.fetchYearlyLeaderboard(10),
    ]).subscribe((x) => {
      let monthly = this.fantasyLeaderboardQuery.getMonthlyLeaderboard() as FantasyLeaderboard[];
      this.yearlyLeaderboards = this.fantasyLeaderboardQuery.getYearlyLeaderboard();

      let today = new Date();

      for (let index = 1; index <= 12; index++) {
        this.monthlyLeaderboard.push({
          month: moment.monthsShort(index - 1) + ' ' + today.getFullYear(),
          entries: monthly.filter((x: FantasyLeaderboard) => {
            return ((x.FantasyMonth as unknown) as number) === index;
          }),
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }
}
