<ng-container *ngIf="loaded$ | async; else loading">
  <ng-container *ngIf="children$ | async as children">
    <div class="mb-3">
      <div class="card bg-primary border text-white kids-added-card">
        <div class="card-body py-2 px-3">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="kids-text">
                <h3 class="heading-text mt-lg-3 mt-2 d-flex align-items-center">
                  {{ 'child.list.kids.title' | translate }}
                  <span class="length-num ms-5 text-primary">{{ children.data?.length }}/{{ children.limit }}</span>
                </h3>
              </div>
            </div>
            <ng-container *ngIf="children.data?.length < children.limit || children.upgradable?.length > 0">
              <div
                class="action-wrapper col-12 col-lg-6 align-items-center d-flex justify-content-lg-end justify-content-center my-2 my-lg-0"
              >
                <a class="btn btn-upgrade-child" [routerLink]="['/child/upgrade']" *ngIf="children.upgradable?.length > 0">
                  <i class="material-symbols-outlined btn-upgrade-child-icon text-white">arrow_circle_up</i>
                  <div class="text-white font-size-14 fw-bold">
                    {{ 'child.panel.upgrade.button' | translate }}
                  </div>
                </a>
                <a
                  class="btn btn-primary add-btn"
                  [routerLink]="['/register/newchildren']"
                  *ngIf="children.data?.length < children.limit"
                >
                  <i class="material-icons add-btn-icon text-white">add_circle</i>
                  <div class="text-white font-size-14 fw-bold">
                    {{ 'child.list.add.kid.button' | translate }}
                  </div>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="children.data?.length > 0">
      <ng-container *ngTemplateOutlet="childrenTemplate"></ng-container>
    </ng-container>

    <!-- Children Template -->

    <ng-template #childrenTemplate>
      <div class="child-list">
        <owl-carousel-o [options]="customOptions">
          <ng-template *ngFor="let child of children.data" carouselSlide>
            <div class="mb-1">
              <fgb-child-panel [child]="child"></fgb-child-panel>
            </div>
          </ng-template>
          <ng-template *ngFor="let empty of children.empty" carouselSlide>
            <div class="no-child-border d-flex align-items-center justify-content-center rounded mx-2 mb-1">
              <img class="logo" src="assets/images/login-registration/ducks.svg" draggable="false" />
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading-container">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
