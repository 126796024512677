<div class="fantasy-leaderboard">
  <div class="leaderboard-back-btn fw-bold d-flex d-lg-none my-2">
    <div class="d-flex align-items-center justify-content-center rounded border border-black w-100">
      <div class="text-primary fw-bold pt-1">{{ 'pick.player.leaderboard.title' | translate }}</div>
    </div>
  </div>
  <div class="card mt-3 rounded border">
    <div class="nav d-flex align-items-center justify-content-center">
      <a class="nav-item w-10 text-center text-black" [ngClass]="{ selected: showMonthly }" (click)="showMonthly = true">
        <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
      </a>
      <a class="nav-item w-10 text-center text-black" [ngClass]="{ selected: !showMonthly }" (click)="showMonthly = false">
        <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
      </a>
    </div>
    <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
      <owl-carousel-o [options]="customOptions" #monthCarousel>
        <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard; let i = index" [id]="i.toString()">
          <div class="py-2 bg-disabled leaderboard-date-toggle">
            <h2 class="text-center fw-bold font-size-14 mb-0">{{ item.month }}</h2>
          </div>
          <ng-container *ngTemplateOutlet="leaderBoard; context: { $implicit: item.entries }"></ng-container>
        </ng-template>
      </owl-carousel-o>
    </div>
    <ng-template #yearly>
      <div class="py-2 yearly-header bg-disabled">
        <h2 class="text-center fw-bold font-size-14 mb-0">{{ year }}</h2>
      </div>
      <ng-container *ngTemplateOutlet="leaderBoard; context: { $implicit: yearlyLeaderboards }"></ng-container>
    </ng-template>
  </div>
</div>

<ng-template #leaderBoard let-fantasyEntries>
  <div class="px-4 pt-3 mb-3">
    <div class="row mb-2 fw-bold font-size-12">
      <div class="col-2 col-lg-3">{{ 'pick.player.leaderboard.rank' | translate }}</div>
      <div class="col-6">{{ 'pick.player.leaderboard.name' | translate }}</div>
      <div class="col-4 col-lg-3">{{ 'pick.player.leaderboard.points' | translate }}</div>
    </div>
    <div
      class="font-size-14 mb-2 fantasy-row"
      *ngFor="let entry of fantasyEntries; let i = index"
      [ngClass]="{ 'current-user-row': entry.IsCurrentUser }"
    >
      <hr class="mx-0 my-2" *ngIf="entry.Position > i + 1" />
      <div class="row my-2" [ngClass]="{ 'rounded bg-primary text-white py-2 mx-0 mb-2 current-user': entry.IsCurrentUser }">
        <div class="col-2 col-lg-3 fw-bold">
          {{ entry.Position }}
        </div>
        <div class="col-6 text-capitalize" [ngClass]="{ 'text-muted': !entry.IsCurrentUser }">
          {{ entry.FullName }}
        </div>
        <div class="col-3 col-lg-2" [ngClass]="{ 'text-muted': !entry.IsCurrentUser }">
          {{ entry.Points / 100 | FortressCurrencyPipe : 'PTS' }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
