<ng-container *ngIf="announcements$ | async as announcements; else loadingRef">
  <div class="announcements-carousel" *ngIf="announcements.length > 0">
    <h2>Announcements</h2>
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let announcement of announcements">
        <ng-template carouselSlide>
          <fgb-announcement-content [announcement]="announcement"></fgb-announcement-content>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
