import { Component, Input, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-mixed-loyalty-nav',
  templateUrl: './mixed-loyalty-nav.component.html',
  styleUrls: ['./mixed-loyalty-nav.component.scss'],
})
export class MixedLoyaltyNavComponent implements OnInit {
  @Input() showWalletButton: boolean = true;
  populations = Populations;
  screenType = ScreenType;
  
  constructor() {}

  ngOnInit() {}
}
