import { Component, OnInit } from '@angular/core';
import { CompanyQuery, Company, config, WorksheetService, WorksheetQuery, SurveyQuery, Survey, SurveyType, Worksheet } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GameQuery, GameService, Game } from '@fgb/core';
import { GameDataSource } from '@fgb/core';
import * as moment from 'moment';

@Component({
  selector: 'fgb-tailgate-page',
  templateUrl: './tailgate-page.component.html',
  styleUrls: ['./tailgate-page.component.scss'],
})
export class TailgatePageComponent implements OnInit {
  config = config;
  companies$: Observable<Company[]>;
  game$: Observable<Game>;
  quizzes$: Observable<Survey[]>;
  predictor$: Observable<Survey[]>;
  polls$: Observable<Survey[]>;
  worksheets$: Observable<Worksheet[]>;

  constructor(
    private worksheetService: WorksheetService,
    private worksheetQuery: WorksheetQuery,
    private router: Router,
    private companyQuery: CompanyQuery,
    private gameQuery: GameQuery,
    private gameService: GameService,
    private surveyQuery: SurveyQuery
  ) {}

  ngOnInit() {
    if (!this.worksheetQuery.hasRoute(this.router.url.replace('/', ''))) {
      firstValueFrom(this.worksheetService.fetchWorksheetByRoute(this.router.url.replace('/', '')));
    }

    this.worksheets$ = this.worksheetQuery.getWorksheetByRoute(this.router.url.replace('/', ''));
    this.quizzes$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Trivia);
    this.predictor$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Predictor);
    this.polls$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Poll);

    this.companies$ = this.companyQuery.selectCompanies().pipe(
      map((companies) => {
        return companies.slice(0, 4);
      })
    );

    firstValueFrom(this.gameService.fetchGames('', GameDataSource.Club)).then(() => {
      this.game$ = this.gameQuery.selectAllGames().pipe(
        // Get all future games
        map((games) => games.filter((game) => moment.utc(game.Date).isAfter(moment.utc().startOf('day')))),
        // Order by date
        map((games) => games.sort((first, second) => moment(second.Date).diff(moment.utc(first.Date)))),

        map((games) =>
          games.reduce((nextGame, currentGame) => {
            return currentGame;
          })
        )
      );
    });
  }

  isGameToday(date: Date) {
    return true;
  }
}
