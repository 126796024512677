import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'fgb-child-upgrade-layout-page',
  templateUrl: './child-upgrade-layout-page.component.html',
  styleUrl: './child-upgrade-layout-page.component.scss'
})
export class ChildUpgradeLayoutPageComponent {

  constructor(private renderer: Renderer2) {
    this.removeTheme();
  }
  
  /**
   * Remove all theme classes from the body element.
   */
  removeTheme(): void{
    if( document.body?.classList.length > 0){
      document.body.classList.forEach((className) => {
        if(className.includes('theme')){
          this.renderer.removeClass(document.body, className);
        }
      });
    }
  }
}
