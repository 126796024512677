import { Component, OnInit } from '@angular/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-achievements-page',
  templateUrl: './achievements-page.component.html',
  styleUrls: ['./achievements-page.component.scss'],
})
export class AchievementsPageComponent implements OnInit {
  populations = Populations;

  constructor() {}

  ngOnInit(): void {}
}
