<div
  class="survey-answer row my-3"
  [ngClass]="{
    portrait: displayType === answerDisplayType.ImageAndText
  }"
>
  <div class="survey-answer-container w-100" [ngClass]="hasSelectedCorrect ? 'justify-content-center' : 'justify-content-around'">
    <div
      class="survey-answer-item m-2"
      [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
      *ngFor="let answer of selectedAnswers"
    >
      <ng-container
        *ngTemplateOutlet="answerItemTemplate; context: { $implicit: answer, correct: hasSelectedCorrect }"
      ></ng-container>
    </div>
    <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
      <ng-container *ngTemplateOutlet="answerItemTemplate; context: { $implicit: correctAnswer, correct: true }"></ng-container>
    </div>
  </div>
  <div class="survey-answer-text-container mx-auto mb-lg-5 mb-4 mt-lg-5 mt-3">
    <ng-container *ngIf="hasSelectedCorrect; else incorrect">
      <div class="text-success">
        <div>
          <ng-container *ngIf="pointsWon; else noPoints">
            <div class="text-center fw-bold font-size-14">
              {{ 'predictor.completed.processed.success.text' | translate : { fgbdefault: 'Correct Answer !' } }}
            </div>
            <ng-container *notInPopulation="[populations.Partial, populations.Default]">
              <div class="text-center points-won fw-bold">+{{ pointsWon }} Points</div>
            </ng-container>
          </ng-container>
          <ng-template #noPoints>
            <div class="fw-bold font-size-14">
              {{ 'predictor.completed.processed.success.text' | translate : { fgbdefault: 'Correct Answer !' } }}
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #incorrect>
      <div class="text-danger">
        <div>
          <div class="text-center fw-bold font-size-14">
            {{ 'predictor.completed.processed.wrong.text' | translate : { fgbdefault: 'Wrong Answer !' } }}
          </div>
          <div class="text-center points-won fw-bold">
            {{ 'predictor.completed.processed.wrong.message' | translate : { fgbdefault: 'Better luck next time' } }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #answerItemTemplate let-answer let-correct="correct">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
      <img
        class="image-container border-0 border-bottom"
        src="{{ answer.ImageURL | contentImage : '7' }}"
        alt="{{ answer.ImageURL }}"
      />
      <div
        class="answer-text text-center text-white"
        [ngClass]="{
          'bg-success': correct,
          'bg-danger': !correct
        }"
      >
        <span>{{ answer.Title }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="answerDisplayType.Text">
      <div
        class="answer-text-landscape text-center text-white"
        [ngClass]="{
          'bg-success': correct,
          'bg-danger': !correct
        }"
      >
        <span>{{ answer.Title }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="answerDisplayType.Image">
      <img class="image-container rounded-4" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
    </ng-container>
  </ng-container>
</ng-template>
