import { inject } from '@angular/core';
import { Address, AddressQuery, AddressService, AddressType, MemberDetails, MemberQuery } from '@fgb/core';
import { combineLatest, map, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AddressesResolver {
  private addressQuery = inject(AddressQuery);
  private memberQuery = inject(MemberQuery);
  private addressService = inject(AddressService);
  constructor() {}

  // Logic taken from the upgrade children component
  resolve(route: ActivatedRouteSnapshot) {
    return combineLatest([
      // @TODO: this is calling twice, called in the guard resolver also called here... need to find a better way to do this
      this.addressService.fetchAddresses().pipe(switchMap(() => this.addressQuery.selectAddresses([AddressType.DefaultAddress]))),
      this.memberQuery.selectMemberDetails()
    ]).pipe(
      map(([addresses, member]) => {
        const billingAddress = this._getDefaultBillingAddress(addresses, member);
        const shippingAddress = this._getDefaultShippingAddress(addresses, member) || { ...billingAddress, AddressName: 'Shipping Address' };
        
        return {
          addresses,
          billing: billingAddress,
          shipping: shippingAddress
        }
      })
    )
  }

  private _getDefaultBillingAddress(addresses: Address[], user: MemberDetails | undefined): Address {
    if (addresses) {
      let billingAddress1 = addresses.filter((a) => a.AddressType === AddressType.BillingAddress1)[0] as Address;
      if (billingAddress1) {
        return billingAddress1;
      }

      let billingAddress2 = addresses.filter((a) => a.AddressType === AddressType.BillingAddress2)[0] as Address;
      if (billingAddress2) {
        return billingAddress2;
      }
    }
    return {
      AddressName: 'Billing Address',
      FirstName: user?.FirstName,
      Surname: user?.Surname,
      Street: user?.Street,
      Town: user?.Town,
      County: user?.County,
      Postcode: user?.PostCode,
      Country: user?.Country,
      EmailAddress: user?.EmailAddress1,
      PortalId: user?.PortalId,
    } as Address;
  }

  private _getDefaultShippingAddress(addresses: Address[], user: MemberDetails | undefined): Address | null {
    if (addresses) {
      let shippingAddress1 = addresses.filter((a) => a.AddressType === AddressType.ShippingAddress1)[0] as Address;
      if (shippingAddress1) {
        return shippingAddress1;
      }

      let shippingAddress2 = addresses.filter((a) => a.AddressType === AddressType.ShippingAddress2)[0] as Address;
      if (shippingAddress2) {
        return shippingAddress2;
      }
    }

    if (user) {
      return {
        AddressName: 'Shipping Address',
        FirstName: user?.FirstName,
        Surname: user?.Surname,
        Street: user?.Street,
        Town: user?.Town,
        County: user?.County,
        Postcode: user?.PostCode,
        Country: user?.Country,
        EmailAddress: user?.EmailAddress1,
        PortalId: user?.PortalId,
      } as Address;
    } else {
      
      return null;
    }
  }


}