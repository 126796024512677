import { Component, OnInit, Optional, Self } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Country } from '@fgb/core';

@Component({
  selector: 'fgb-address-form',
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent implements OnInit{
  form: FormGroup;
  regions: any[];


  constructor(@Optional() @Self() private container: ControlContainer){

  }

  ngOnInit(): void {
    
    if( this.container ){
      this.form = this.container.control as FormGroup;
      this.form.updateValueAndValidity();
    }
  }

  onCountryChange(event: Country): void {
    this.regions = event?.Regions;
  }

}
