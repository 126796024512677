<div class="card rounded border voucher-box">
  <div class="card-content px-3">
    <h3 class="mb-lg-0 text-center text-white">{{ 'promo.code.title' | translate }}</h3>
    <div>
      <span class="input-group mt-lg-1">
        <input
          type="text"
          [(ngModel)]="voucherCode"
          [placeholder]="'vouchers.placeholder' | translate"
          class="form-control text-center border-0 px-5 px-lg-0"
          (keyup.enter)="redeemVoucher()"
        />
        <div class="input-group-append">
          <button class="btn text-white fw-bold py-0" type="button" (click)="redeemVoucher()" [disabled]="disableButton">
            <i class="material-icons">chevron_right</i>
          </button>
        </div>
      </span>
      <div class="result-box pt-1 text-center">
        <div class="result mt-0">
          <fgbcl-errors></fgbcl-errors>
          <div
            class="font-size-12"
            [ngClass]="{
              empty: isEmpty,
              success: sucessfulRedemption,
              error: !sucessfulRedemption
            }"
            *ngIf="redemptionResult"
          >
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
