import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PortalProduct, PortalProductsQuery, PortalProductsService } from '@fgb/core';

export const membershipResolver: ResolveFn<Observable<any>> = (route, state) => {
  const portalProductService = inject(PortalProductsService);
  const portalProductQuery = inject(PortalProductsQuery);

  return portalProductService
  .fetchPortalProducts()
  .pipe(
    map(() => {
      let portalProducts = portalProductQuery.getAll() as PortalProduct[];
      // Logic taken from upgrade children component
      return portalProducts.filter(pp => pp.MerchantPrice > 0 && !pp.DisplayName.includes('Rookie') && pp.ProductType === 4);
    })
  );

};
