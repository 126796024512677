import { Component, OnInit } from '@angular/core';
import {
  PortalProduct,
  PortalProductsQuery,
  PortalProductsSubscriptionsQuery,
  PortalProductSubscription,
  Cart,
  CartQuery,
  CartService,
} from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss'],
})
export class SubscriptionsListComponent implements OnInit {
  productTypes: number[] = [1, 3];
  membershipPortalProducts: PortalProduct[];
  subscriptionPortalProducts: PortalProduct[];
  maxSubs: number;
  activeSubs: number;
  purchasedProducts: PortalProductSubscription[];
  foundMembership: boolean = false;
  foundSubscription: boolean = false;
  notPurchasedActiveSubs: PortalProduct[] = [];
  individualProduct: PortalProduct;
  purchasedSubProductType: number[] = [3];
  purchasedSubProducts: PortalProductSubscription[];
  allProducts: number;
  // modal sticky basket
  items$: Observable<Cart[]>;
  totalCost$: Observable<number>;

  constructor(
    private portalProductsQuery: PortalProductsQuery,
    private portalProductsSubscriptionsQuery: PortalProductsSubscriptionsQuery,
    private cartQuery: CartQuery,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.membershipPortalProducts = this.portalProductsQuery.getAllProductsByProductType(this.productTypes[0], undefined);
    this.subscriptionPortalProducts = this.portalProductsQuery.getAllProductsByProductType(this.productTypes[1], undefined);

    firstValueFrom(
      this.portalProductsSubscriptionsQuery.selectSubscriptionsByProductTypes(this.productTypes).pipe(
        tap((items) => {
          this.purchasedProducts = items;
        })
      )
    );

    firstValueFrom(
      this.portalProductsSubscriptionsQuery.selectSubscriptionsByProductTypes(this.purchasedSubProductType).pipe(
        tap((subItems) => {
          this.purchasedSubProducts = subItems;
        })
      )
    );

    this.findExistingMembership();
    this.findExistingSubs();

    this.getActiveNonPurchasedSubs();

    // active and maximum subs count
    this.activeSubs = this.purchasedSubProducts.length;
    this.maxSubs = this.notPurchasedActiveSubs.length + this.purchasedSubProducts.length;

    this.allProducts =
      this.notPurchasedActiveSubs.length +
      this.purchasedProducts.length +
      this.portalProductsQuery.getAllProductsByProductType(this.productTypes[0], undefined).length;

    // modal basket item count and total cost
    this.items$ = this.cartQuery.selectAllSubscriptionCartData();
    this.totalCost$ = this.cartQuery.calculateTotalCost();
  }

  // check to see if there is a product type 1 in the purchased products list
  findExistingMembership() {
    this.purchasedProducts.forEach((product) => {
      if (product.ProductType === 1) {
        this.foundMembership = true;
        return;
      }
    });
  }
  // check to see if there is a product type 3 in the purchased products list
  findExistingSubs() {
    this.purchasedProducts.forEach((product) => {
      if (product.ProductType === 3) {
        this.foundSubscription = true;
        return;
      }
    });
  }

  // get portal products of type 3 which are active and not purchased
  getActiveNonPurchasedSubs() {
    let portalProductIds: number[] = [];
    let purchasedProductIds: number[] = [];
    this.subscriptionPortalProducts.forEach((products) => {
      portalProductIds.push(products.ProductId);
    });
    this.purchasedProducts.forEach((purchasedProduct) => {
      purchasedProductIds.push(purchasedProduct.ProductId);
    });

    let nonPurchasedIds = portalProductIds.filter((x) => !purchasedProductIds.includes(x));

    nonPurchasedIds.forEach((ids) => {
      let item: PortalProduct = this.getProductsById(ids);
      this.notPurchasedActiveSubs.push(item);
    });
  }

  // get a portal product by product ID
  getProductsById(id: number): PortalProduct {
    this.subscriptionPortalProducts.forEach((prod) => {
      if (id === prod.ProductId) {
        this.individualProduct = prod;
      }
    });
    return this.individualProduct;
  }

  // remove item from basket
  remove(cartItem: Cart) {
    this.cartService.remove(cartItem.id);
  }

  // clear and cancel items in subs basket
  clearSubsBasket() {
    this.cartQuery.removeItems(this.cartQuery.getMemberPortalProductCart());
  }
}
