import { NgModule } from '@angular/core';
import { UserModule } from '../user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LottosWalletListComponent } from './components/lottos-wallet-list/lottos-wallet-list.component';
import { LottosWalletListItemComponent } from './components/lottos-wallet-list-item/lottos-wallet-list-item.component';
import { LottosWalletDetailsComponent } from './components/lottos-wallet-details/lottos-wallet-details.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LayoutModule } from '../layouts/layout.module';
import { WorksheetListComponent } from './components/worksheet-list/worksheet-list.component';
import { WorksheetItemComponent } from './components/worksheet-item/worksheet-item.component';
import { CollectAddressModal } from './components/collect-address-modal/collect-address-modal.component';
import { SubscriptionsListComponent } from './components/subscriptions-list/subscriptions-list.component';
import { SubscriptionsListItemComponent } from './components/subscriptions-list-item/subscriptions-list-item.component';
import { SubscriptionsListItemRenewalsComponent } from './components/subscriptions-list-item-renewals/subscriptions-list-item-renewals.component';
import { SubscriptionsListItemSubscribedComponent } from './components/subscriptions-list-item-subscribed/subscriptions-list-item-subscribed.component';
import { CartItemSubscriptionsComponent } from './components/cart-item-subscriptions/cart-item-subscriptions.component';
import { CartSubscriptionsModalComponent } from './components/cart-item-subscriptions/cart-subscriptions-modal/cart-subscriptions-modal.component';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { ScorecardListViewComponent } from './components/scorecard-list-view/scorecard-list-view.component';
import { AuctionDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-details';
import { AuctionPanelModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-panel';
import { AuctionListModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-list';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { LottoDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lotto-details';
import { LottosListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list-item';
import { LottosListModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list';
import { BadgeListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-list-item';
import { MarketplaceDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-details';
import { MarketplaceListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list-item';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';
import { SortBarModule } from '@fgb/portal-component-library/src/lib/shared/sort-bar';
import { CampaignsOffersListComponent } from './components/campaigns-offers-list/campaigns-offers-list.component';
import { CampaignsListItemComponent } from './components/campaigns-offers-list/campaigns-list-item/campaigns-list-item.component';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';

@NgModule({
  declarations: [
    LottosWalletListComponent,
    LottosWalletListItemComponent,
    LottosWalletDetailsComponent,
    WorksheetListComponent,
    WorksheetItemComponent,
    CollectAddressModal,
    SubscriptionsListComponent,
    SubscriptionsListItemComponent,
    SubscriptionsListItemRenewalsComponent,
    SubscriptionsListItemSubscribedComponent,
    CartItemSubscriptionsComponent,
    CartSubscriptionsModalComponent,
    ScorecardListViewComponent,
    CampaignsOffersListComponent,
    CampaignsListItemComponent
  ],
  exports: [
    LottosWalletListComponent,
    LottosWalletListItemComponent,
    LottosWalletDetailsComponent,
    WorksheetListComponent,
    WorksheetItemComponent,
    CollectAddressModal,
    SubscriptionsListComponent,
    SubscriptionsListItemComponent,
    SubscriptionsListItemRenewalsComponent,
    SubscriptionsListItemSubscribedComponent,
    CartItemSubscriptionsComponent,
    CartSubscriptionsModalComponent,
    LottosWalletListItemComponent,
    ScorecardListViewComponent,
    CampaignsOffersListComponent,
    CampaignsListItemComponent
  ],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    UserModule,
    LayoutModule,
    ErrorsModule,
    ShortLongDescriptionModule,
    AuctionDetailsModule,
    AuctionPanelModule,
    AuctionListModule,
    LottoDetailsModule,
    LottosListModule,
    LottosListItemModule,
    BadgeListItemModule,
    MarketplaceDetailsModule,
    MarketplaceListModule,
    MarketplaceListItemModule,
    LoyaltyIconModule,
    CountdownModule,
    SortBarModule,
    FGBSharedModule,
  ],
})
export class LoyaltyModule {}
