<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <div id="brand-logo"></div>
    </a>
    <ng-container *inPopulation="[populations.Orange, populations.Partner]">
      <a class="nav-item" [routerLink]="['/rewards/marketplace']">
        <span class="icon material-icons material-mobile-nav">redeem</span>
      </a>
    </ng-container>
    <ng-container *inPopulation="[populations.Partial]">
      <a class="nav-item" [routerLink]="['/rewards/events']">
        <span class="icon material-icons material-mobile-nav">local_activity</span>
      </a>
    </ng-container>
    <ng-container *inPopulation="[populations.Kids]">
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons material-mobile-nav">account_balance_wallet</span>
      </a>
      <a class="nav-item" [routerLink]="['/challenges']">
        <i class="icon material-icons material-mobile-nav">flag</i>
      </a>
    </ng-container>
    <ng-container *inPopulation="[populations.Default]">
      <a class="nav-item" [routerLink]="['/games']">
        <i class="icon material-icons material-mobile-nav">extension</i>
      </a>
    </ng-container>
    <ng-container *inPopulation="[populations.Orange, populations.Partner, populations.Partial, populations.Default]">
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons material-mobile-nav">account_balance_wallet</span>
      </a>
    </ng-container>
    <!--Notifications-->
    <div class="nav-item">
      <fgb-notification-button></fgb-notification-button>
    </div>
    <button class="nav-item fw-bold navbar-toggler m-0 px-2" (click)="isCollapsed = !isCollapsed">
      <span class="icon material-icons material-mobile-nav hamburger-icon">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon fw-bold icon-cancel-circle"></span>
      </button>
      <!-- Club Logo -->
      <div class="d-flex align-items-center mt-4 mb-2 mx-3 mobile-nav-header">
        <div id="brand-logo"></div>
        <button class="btn-nav-close" (click)="isCollapsed = !isCollapsed"><i class="material-icons">close</i></button>
      </div>
      <div class="member-info">
        <div class="w-100" *ngIf="memberCard$ | async as card">
          <div class="barcode-content mx-3 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <!--Name and ID-->
              <div class="card-id text-start">
                <h5 class="mb-1 card-name" *ngIf="memberDetails$ | async as memberDetails">
                  {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
                </h5>
                <div class="d-flex product-code font-size-12">
                  <ng-container *inPopulation="[populations.Orange]">
                    <div>{{ 'membercard.productcode.orange' | translate : { fgbdefault: 'Season' } }} -</div>
                    <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
                  </ng-container>

                  <ng-container *inPopulation="[populations.Default]">
                    <div>{{ 'membercard.productcode.fan' | translate : { fgbdefault: 'Ducks Fan' } }} -</div>
                    <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
                  </ng-container>

                  <ng-container *inPopulation="[populations.Partial]">
                    <div>{{ 'membercard.productcode.partial' | translate : { fgbdefault: 'Plan Holder' } }} -</div>
                    <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
                  </ng-container>

                  <ng-container *inPopulation="[populations.Partner]">
                    <div>{{ 'membercard.productcode.partner' | translate : { fgbdefault: 'Partner' } }} -</div>
                    <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
                  </ng-container>

                  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
                    <ng-container *inPopulation="[populations.Kids]">
                      <div>{{ 'mobile.menu.kids' | translate : { fgbdefault: 'Wild Wingers' } }} -</div>
                      <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <ng-container *notInPopulation="[populations.Kids, populations.Default]">
              <div class="d-flex align-items-center py-4 member-info-container">
                <ng-container *inPopulation="[populations.Orange, populations.Partner, populations.Partial]">
                  <div class="d-flex align-items-end mb-1 w-100">
                    <ng-container *notInPopulation="[populations.Partial]">
                      <div class="card-account-no text-start pe-3" *ngIf="pointsBalance$">
                        <div class="value">{{ pointsBalance$ | async | number }}</div>
                        <label class="mb-0">{{ 'member.card.points.balance' | translate }}</label>
                      </div>
                      <div class="card-id ecash-balance text-start ps-3 pe-3" *ngIf="ecash$">
                        <div class="value">{{ ecash$ | async | FortressCurrencyPipe }}</div>
                        <label class="mb-0">{{ 'member.card.ecash.balance' | translate }}</label>
                      </div>
                    </ng-container>
                    <div class="card-id ecash-balance text-start ps-3 pe-3">
                      <div class="value">{{ card?.DiscountLevel ?? 0 }}%</div>
                      <label class="mb-0">{{ 'mobile.nav.menu.discount.label' | translate : { fgbdefault: 'F&B' } }}</label>
                    </div>
                    <div class="card-id ecash-balance text-start ps-3 pe-3">
                      <div class="value">{{ card?.RetailDiscountLevel ?? 0 }}%</div>
                      <label class="mb-0">{{
                        'mobile.nav.menu.retail.discount.label' | translate : { fgbdefault: 'Merch' }
                      }}</label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
                  <ng-container *ngIf="cardToken$ | async as cardToken; else addCard">
                    <div class="text-start card-text font-size-12">
                      <ng-container *ngIf="!cardToken.Frozen; else frozen">
                        {{ 'barcode.payment.card' | translate }}
                        <span class="fw-bold text-success"> {{ 'barcode.payment.card.active' | translate }} </span>
                      </ng-container>
                      <ng-template #frozen>
                        {{ 'barcode.payment.card' | translate }}
                        <span class="fw-bold text-frozen"> {{ 'barcode.payment.card.frozen' | translate }}</span>
                      </ng-template>
                      <div>
                        <span
                          >{{ cardToken?.CardNumber | creditCardNumber | slice : -9 }}
                          <ng-container [ngSwitch]="cardToken!.CardType">
                            <img class="card-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
                            <img class="card-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
                            <img class="card-logo" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.svg" />
                            <img class="card-logo" *ngSwitchCase="4" src="assets/images/payment-icons/_Discover.svg" />
                            <img class="card-logo" *ngSwitchCase="5" src="assets/images/payment-icons/_JCB.svg" />
                            <img class="card-logo" *ngSwitchCase="7" src="assets/images/payment-icons/_DinnersClub.svg" />
                            <img class="card-logo" *ngSwitchCase="8" src="assets/images/payment-icons/_UnionPay.svg" />
                            <div class="card-logo" *ngSwitchDefault></div>
                          </ng-container>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #addCard>
                    <button
                      type="button"
                      class="btn wallet-button border-0 p-0 d-flex justify-content-center align-items-center"
                      [routerLink]="['/payment']"
                    >
                      <span class="icon icon-settings-1 ms-2"></span>
                      <span class="ms-1 wallet-button-text text-white">{{ 'wallet.add.payment.card' | translate }}</span>
                    </button>
                  </ng-template>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *inPopulation="[populations.Default]">
              <div class="mb-4"></div>
            </ng-container>
            <ng-container *inPopulation="[populations.Kids]">
              <hr />
              <div class="d-flex justify-content-center align-items-center">
                <a
                  class="guardian-btn text-primary d-flex align-items-center justify-content-center fw-bold"
                  (click)="switchToGuardian()"
                >
                  <i class="icon material-icons me-1">logout</i>
                  {{ 'mobile.nav.back.to.guardians.button' | translate : { fgbdefault: 'Back to Guardian' } }}
                </a>
              </div>
              <hr />
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <ng-container *notInPopulation="[populations.Kids]">
          <a
            class="nav-item fw-bold large-item"
            [routerLink]="['/account']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">person</span>
            <div class="nav-text fw-bold">{{ 'nav.account' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>

        <ng-container *inPopulation="[populations.Kids]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/marketplace']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/challenges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>

          <a class="nav-item" [routerLink]="['/games']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/worksheet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons-outlined material-mobile-dropdown me-3" aria-hidden="true">draw</span>
            <div class="nav-text fw-bold">{{ 'nav.worksheet' | translate : { fgbdefault: 'Activities' } }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.gallery'">
            <a
              class="nav-item"
              [routerLink]="['/gallery']"
              [routerLinkActive]="'active'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">photo_library</span>
              <div class="nav-text fw-bold">{{ 'nav.gallery' | translate : { fgbdefault: 'Gallery' } }}</div>
              <i class="material-icons ms-auto">chevron_right</i>
            </a>
          </ng-container>
        </ng-container>

        <ng-container *inPopulation="[populations.Default]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/challenges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>
        <ng-container *inPopulation="[populations.Partial]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>

          <a
            class="nav-item"
            [routerLink]="['/rewards/events']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">local_activity</span>
            <div class="nav-text fw-bold">{{ 'nav.experiences' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/challenges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>
        <ng-container *inPopulation="[populations.Orange, populations.Partner]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/marketplace']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold">{{ 'nav.rewards' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold">{{ 'nav.wallet' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/challenges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold">{{ 'nav.challenges' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
          <a
            class="nav-item"
            [routerLink]="['/payment']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">credit_card</span>
            <div class="nav-text fw-bold">{{ 'nav.payment' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>

        <ng-container *notInPopulation="[populations.Kids]">
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <a
              class="nav-item"
              [routerLink]="['/guardians']"
              [routerLinkActive]="'active'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">family_restroom</span>
              <div class="nav-text fw-bold">{{ 'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' } }}</div>
              <i class="material-icons ms-auto">chevron_right</i>
            </a>
          </ng-container>
          <a class="nav-item" [routerLink]="['/games']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">extension</span>
            <div class="nav-text fw-bold">{{ 'nav.games' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>

          <ng-container *notInPopulation="[populations.Default, populations.Partner]">
            <a class="nav-item" [href]="'home.page.giveaways.link' | translate" target="_blank">
              <span class="icon material-symbols-outlined material-mobile-dropdown me-3" aria-hidden="true">award_star</span>
              <div class="nav-text fw-bold">{{ 'nav.giveaways' | translate }}</div>
              <i class="material-icons ms-auto">chevron_right</i>
            </a>
            <a class="nav-item" [href]="'home.game.exchange.link' | translate" target="_blank">
              <span class="icon material-symbols-outlined material-mobile-dropdown me-3" aria-hidden="true"
                >confirmation_number</span
              >
              <div class="nav-text fw-bold">{{ 'nav.game.exchange' | translate }}</div>
              <i class="material-icons ms-auto">chevron_right</i>
            </a>
          </ng-container>

          <!-- <a class="card rounded" [href]="'home.page.giveaways.link' | translate" target="_blank">
            <label>
              <i class="material-symbols-outlined me-2">award_star</i>
              <span>{{ 'guaranteed.giveaways.title' | translate }}</span>
            </label>
            <span>{{ 'find.out.more' | translate }}</span>
          </a>
          <a class="card rounded" [href]="'home.game.exchange.link' | translate" target="_blank">
            <label>
              <i class="material-symbols-outlined me-2">confirmation_number</i>
              <span> {{ 'future.game.exchange.title' | translate }}</span>
            </label>
            <span>{{ 'find.out.more' | translate }}</span>
          </a> -->

          <!-- <a class="nav-item" href="https://am.ticketmaster.com/ducks/" target="_blank">
            <span class="icon material-icons material-mobile-dropdown me-3">confirmation_number</span>
            <div class="nav-text fw-bold">{{ 'nav.ticketing' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a> -->
          <a
            class="nav-item"
            [routerLink]="['/contact']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">email</span>
            <div class="nav-text fw-bold">{{ 'nav.contact.us' | translate }}</div>
            <i class="material-icons ms-auto">chevron_right</i>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">article</span>
          <div class="nav-text fw-bold">{{ 'nav.terms' | translate }}</div>
          <i class="material-icons ms-auto">chevron_right</i>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">help</span>
          <div class="nav-text fw-bold">{{ 'nav.faq' | translate }}</div>
          <i class="material-icons ms-auto">chevron_right</i>
        </a>

        <ng-container *notInPopulation="[populations.Kids]">
          <a class="nav-item logout" (click)="logout()">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text fw-bold">{{ 'nav.log.out' | translate }}</div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
