<div role="contentinfo" class="footer text-white text-center">
  <div class="footer-quicklinks bg-primary d-block w-100"></div>
  <div class="footer-text d-flex align-items-center justify-content-center w-100">
    <div class="footer-content d-flex">
      <img class="footer-logo mb-3" src="assets/images/footer/footer-logo.svg" draggable="false" />
      <div
        class="text-white font-size-12 ps-3 text-center footer-rights"
        [innerHTML]="'nav.club.rights' | translate | markdownTranslate"
      ></div>
    </div>
  </div>
</div>
