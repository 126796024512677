<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<ng-container *notInPopulation="[populations.Partial]">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</ng-container>

<ng-container *inPopulation="[populations.Partial]">
  <div class="d-none d-lg-block">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </div>
</ng-container>

<div class="container mt-4">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemTypes]="[3, 5]"
    [eventItemView]="true"
    [defaultSort]="0"
  ></fgbcl-marketplace-list>
</div>
