<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue : keepOrder">
      <h5 class="transaction-date mt-4 mb-3">{{ day.key }}</h5>
      <div class="mb-0">
        <div class="card my-3 py-3 px-4 transaction-results-card" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-start">
              <div class="fw-bold text-black transaction-text">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe : 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-end pt-2">
              <div
                class="fw-bold transaction-text text-capitalize"
                [ngClass]="{
                  'text-success': transaction.Amount >= 0,
                  'text-danger': transaction.Amount < 0
                }"
              >
                {{ transaction.Amount >= 0 ? '+ ' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe : (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!transactions || !hasTransactions">
      <div class="w-100 text-center no-items pt-4">
        <div class="no-results-placeholder-description card border px-2">
          {{ 'transactions.page.placeholder' | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
