<ng-container *ngIf="mobilePassUrl$ | withLoading | async as mobilePass">
  <!-- Mobile Pass available-->
  <ng-container *ngIf="mobilePass.data">
    <!--Desktop-->
    <!-- <ng-container *ngIf="deviceDetector.isDesktop()">
      <button class="btn btn-danger" (click)="downloadPassDesktop(mobilePass.data.url)">Download Mobile Pass</button>
    </ng-container> -->
    <!--Mobile-->
    <ng-container *ifScreenSize="screenType.Mobile">
      <ng-container *ngIf="deviceDetector.isMobile()">
        <!--ANDROID-->
        <div class="d-flex justify-content-center" *ngIf="deviceDetector.os.toUpperCase() === 'ANDROID'">
          <a [href]="mobilePass.data.url" target="_blank">
            <button class="btn btn-android">
              <label class="accessibility-only">{{ 'mobile.pass.android.screenreader' | translate }}</label>
              <img src="assets/images/passes/google.svg" />
            </button>
          </a>
        </div>
        <!--IOS-->
        <div class="d-flex justify-content-center" *ngIf="deviceDetector.os.toUpperCase() === 'IOS'">
          <a [href]="mobilePass.data.url" target="_blank">
            <button class="btn">
              <label class="accessibility-only">{{ 'mobile.pass.ios.screenreader' | translate }}</label>
              <img src="assets/images/passes/apple-button.svg" />
            </button>
          </a>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- No Mobile Pass available -->
  <ng-container *ngIf="mobilePass.isEmpty">
    <div class="d-flex justify-content-center py-2">
      <label class="mb-0">Mobile Pass Unavailable</label>
    </div>
  </ng-container>

  <!-- Error -->
  <ng-container *ngIf="mobilePass.error">Error {{ mobilePass.error }}</ng-container>

  <!-- Loading Pass-->
  <ng-container *ngIf="mobilePass.isLoading">
    <fgbcl-loading></fgbcl-loading>
  </ng-container>
</ng-container>
