<div class="d-none d-lg-flex banner">
  <h1>{{ 'payment.page.title' | translate }}</h1>
</div>

<ng-container *inPopulation="[populations.Default]"> <fgb-account-nav></fgb-account-nav> </ng-container>

<ng-container *inPopulation="[populations.Orange]">
  <div class="d-block d-lg-none">
    <div class="container pt-4">
      <fgb-barcode></fgb-barcode>
    </div>
    <ul class="nav nav-secondary payment-nav">
      <a
        class="nav-item primary-theme py-2"
        [routerLink]="['/payment']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="icon material-icons material-mobile-nav" aria-hidden="true">credit_card</span>
        <label>{{ 'navbar.payment' | translate }}</label>
      </a>
      <a
        class="nav-item primary-theme py-2"
        [routerLink]="['/rewards/wallet']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="icon material-icons material-mobile-nav" aria-hidden="true">account_balance_wallet</span>
        <label>{{ 'navbar.wallet' | translate }}</label>
      </a>
    </ul>
  </div>
</ng-container>

<div class="container d-none d-lg-block">
  <ng-container *inPopulation="[populations.Orange]">
    <fgbcl-ecash-description></fgbcl-ecash-description>
  </ng-container>
  <ng-container *inPopulation="[populations.Default]">
    <fgb-ecash-description></fgb-ecash-description>
  </ng-container>
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
</div>

<div class="container d-block d-lg-none pt-3">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/cardtoken/result'"
    [failureUrl]="'/cardtoken/failure'"
  ></fgbcl-card-token-list>
  <hr class="horizontal-line w-100" />
  <ng-container *inPopulation="[populations.Orange]">
    <fgbcl-ecash-description></fgbcl-ecash-description>
  </ng-container>
  <ng-container *inPopulation="[populations.Default]">
    <fgb-ecash-description></fgb-ecash-description>
  </ng-container>
</div>

<div class="container mt-4">
  <hr class="d-none d-lg-block horizontal-line" />
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
