<div class="banner d-flex">
</div>

<!--Barcode-->
<div class="d-flex d-lg-none">
  <div class="container barcode-container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!--Vouchers-->
<ng-container *inPopulation="[populations.Default]">
  <div class="container my-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
</ng-container>

<div class="mt-lg-0 mt-2">
  <fgb-game-nav></fgb-game-nav>
</div>

<div class="container mt-4">
  <fgbcl-badge-overview-list></fgbcl-badge-overview-list>
</div>
