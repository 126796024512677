<form [formGroup]="addForm" class="row justify-content-lg-start justify-content-center partner-add-form" (ngSubmit)="submit()">
  <div class="col-lg-8 col-12 row p-0 px-lg-2">
    <div class="col-lg-5 col-12">
      <label class="ps-2">{{ 'partner.add.form.fullname.label' | translate : { fgbdefault: 'Partner Full Name' } }}</label>
      <input formControlName="fullName" placeholder="Full Name Here" class="form-control bg-muted" />
      <p
        class="col-12 ps-0 font-size-10 text-danger mt-1 mb-0"
        *ngIf="addForm.get('fullName')?.hasError('empty') && addForm.get('fullName')?.touched"
      >
        {{ 'partner.add.form.fullname.error.empty' | translate : { fgbdefault: 'Fullname cannot be empty' } }}
      </p>
    </div>
    <div class="offset-lg-1 col-lg-5 col-12">
      <label class="ps-2 mt-4 mt-lg-0">{{ 'partner.add.form.email.label' | translate : { fgbdefault: 'Partner Email' } }}</label>
      <input formControlName="email" placeholder="username@email.com" class="form-control bg-muted" />
    </div>
  </div>
  <div class="col-lg-4 d-flex align-items-start">
    <button
      type="submit"
      [disabled]="addForm.invalid"
      class="btn btn-block btn-primary flex-grow-0 fw-bold me-0 ms-auto font-size-14"
    >
      {{ 'partner.add.form.submit.button' | translate : { fgbdefault: 'Add New Partner' } }}
    </button>
    <div class="col-lg-1 col-2 close-btn">
      <div (click)="close.emit()" class="icon icon-cancel-circle text-grey cursor-pointer"></div>
    </div>
  </div>
  <p class="col-12 font-size-10 text-danger mt-1" *ngIf="errorText">
    {{ errorText }}
  </p>
</form>
