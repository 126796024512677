<div class="banner d-lg-flex d-none" role="banner"></div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-lg-4">
  <div class="row">
    <div class="col-12 col-lg-6 questions-container">
      <div class="d-lg-block d-none">
        <h5 class="mt-lg-0 member-card-title">{{ 'account.member.card.title' | translate }}</h5>
        <fgb-member-card showSeating="true"></fgb-member-card>
      </div>
      <ng-container *notInPopulation="[populations.Default]">
        <fgb-my-representative></fgb-my-representative>
      </ng-container>
      <ng-container *inPopulation="[populations.Default]">
        <ng-container *ngTemplateOutlet="myFavourite"></ng-container>
      </ng-container>
    </div>
    <div class="col-12 col-lg-6 user-details-container mt-3 mt-lg-0">
      <h5 class="mt-lg-0 account-details-title">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
      <ng-container *notInPopulation="[populations.Default]">
        <ng-container *ngTemplateOutlet="myFavourite"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #myFavourite>
  <div class="mt-2 mt-lg-4">
    <h5 class="text-start mt-4 mt-lg-0 edit-question-title">{{ 'account.answer.title' | translate }}</h5>
    <fgbcl-edit-questions></fgbcl-edit-questions>
  </div>
</ng-template>
