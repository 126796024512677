<ng-container *ngIf="representative$ | async as representative">
  <div class="representative">
    <h2 class="representative-title text-uppercase">
      {{ 'representative.title' | translate }}
    </h2>
    <div class="representative-box card">
      <h5 class="representative-name">{{ representative.Name }}</h5>
      <div class="representative-job">{{ representative.JobTitle }}</div>
      <div class="representative-info representative-mail">
        <span class="material-icons">mail</span>
        <span class="text">{{ representative.EmailAddress }}</span>
      </div>
      <div class="representative-info representative-phone">
        <span class="material-icons">phone</span>
        <span class="text">{{ representative.PhoneNumber }}</span>
      </div>
    </div>
  </div>
</ng-container>
