import { Component, OnInit } from '@angular/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss'],
})
export class AccountNavComponent implements OnInit {
  populations = Populations;
  constructor() {}

  ngOnInit() {}
}
