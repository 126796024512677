<div class="card mt-4" [class.disabled]="disabled || isClaimed || inactive || outOfStock">
  <div class="d-flex">
    <img class="benefitImage" src="{{ benefit.ImageFileName | contentImage: '1' }}" />
    <div class="col">
      <h6 class="text-secondary">{{ benefit.Name }}</h6>
      <p class="cursor-pointer" (click)="openReadMoreModal(benefit)">Read More...</p>
    </div>
    <div class="col-3">
      <span
        *ngIf="!disabled && !isClaimed && !inactive && !outOfStock; else notActive"
        class="icon icon-plus position-bottom cursor-pointer fw-bold"
        (click)="openClaimModal(benefit)"
      >
      </span>
      <ng-template #notActive>
        <div class="position-absolute not-active-text" *ngIf="statusText">
          <span>{{ statusText }}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
