<div class="address-form">
  <form [formGroup]="form">
    <div class="form-floating" *ngIf="form?.get('FirstName')">
      <input id="FirstName" class="form-control" name="FirstName" type="text" formControlName="FirstName" />
      <label for="FirstName">{{ 'address.form.firstname.label' | translate }}</label>
      <div class="error-message" *ngIf="form.get('FirstName')?.hasError('required') && form.get('FirstName')?.touched">
        {{ 'address.form.firstname.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('FirstName')?.hasError('empty') && form.get('FirstName')?.touched">
        {{ 'address.form.firstname.error.empty' | translate : { fgbdefault: 'FirstName cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('Surname')">
      <input id="Surname" class="form-control" name="Surname" type="text" formControlName="Surname" />
      <label for="Surname">{{ 'address.form.lastname.label' | translate }}</label>
      <div class="error-message" *ngIf="form.get('Surname')?.hasError('required') && form.get('Surname')?.touched">
        {{ 'address.form.lastname.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Surname')?.hasError('empty') && form.get('Surname')?.touched">
        {{ 'address.form.lastname.error.empty' | translate : { fgbdefault: 'Lastname cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('Street')">
      <input id="Street" class="form-control" name="Street" type="text" formControlName="Street" />
      <label for="Street">{{ 'address.form.street.label' | translate }}</label>
      <div class="error-message" *ngIf="form.get('Street')?.hasError('required') && form.get('Street')?.touched">
        {{ 'address.form.street.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Street')?.hasError('empty') && form.get('Street')?.touched">
        {{ 'address.form.street.error.empty' | translate : { fgbdefault: 'Street cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('Town')">
      <input id="Town" class="form-control" name="Town" type="text" formControlName="Town" />
      <label for="Town">{{ 'address.form.town.label' | translate : { fgbdefault: 'City' } }}</label>
      <div class="error-message" *ngIf="form.get('Town')?.hasError('required') && form.get('Town')?.touched">
        {{ 'address.form.town.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Town')?.hasError('empty') && form.get('Town')?.touched">
        {{ 'address.form.town.error.empty' | translate : { fgbdefault: 'City cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('Country')">
      <fgbcl-select-country
        [label]="'address.form.country.label' | translate : { fgbdefault: 'Country' }"
        formControlName="Country"
        (countryChange)="onCountryChange($event)"
      ></fgbcl-select-country>
      <div class="error-message" *ngIf="form.get('Country')?.hasError('required') && form.get('Country')?.touched">
        {{ 'address.form.country.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Country')?.hasError('empty') && form.get('Country')?.touched">
        {{ 'address.form.country.error.empty' | translate : { fgbdefault: 'Country cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('County')">
      <fgbcl-select-region
        [label]="'address.form.county.label' | translate : { fgbdefault: 'State' }"
        formControlName="County"
        [options]="regions"
      ></fgbcl-select-region>

      <div class="error-message" *ngIf="form.get('County')?.hasError('required') && form.get('County')?.touched">
        {{ 'address.form.county.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('County')?.hasError('empty') && form.get('County')?.touched">
        {{ 'address.form.county.error.empty' | translate : { fgbdefault: 'State cannot be empty.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('Postcode')">
      <input id="Postcode" class="form-control" name="Postcode" type="text" formControlName="Postcode" />
      <label for="Postcode">{{ 'address.form.postcode.label' | translate : { fgbdefault: 'Zip Code' } }}</label>
      <div class="error-message" *ngIf="form.get('Postcode')?.hasError('required') && form.get('Postcode')?.touched">
        {{ 'address.form.postcode.error.required' | translate : { fgbdefault: 'Please enter a valid zip code.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Postcode')?.hasError('empty') && form.get('Postcode')?.touched">
        {{ 'address.form.postcode.error.empty' | translate : { fgbdefault: 'Postcode cannot be empty.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('Postcode')?.hasError('pattern') && form.get('Postcode')?.touched">
        {{ 'address.form.postcode.error.pattern' | translate : { fgbdefault: 'Please enter a valid postcode.' } }}
      </div>
    </div>

    <div class="form-floating" *ngIf="form?.get('HomeNumber')">
      <input id="HomeNumber" class="form-control" name="HomeNumber" type="text" formControlName="HomeNumber" />
      <label for="HomeNumber">{{ 'address.form.homenumber.label' | translate : { fgbdefault: 'Phone Number' } }}</label>
      <div class="error-message" *ngIf="form.get('HomeNumber')?.hasError('required') && form.get('HomeNumber')?.touched">
        {{ 'fgbcl.registration.payment.form.homenumber.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
      </div>
      <div class="error-message" *ngIf="form.get('HomeNumber')?.hasError('empty') && form.get('HomeNumber')?.touched">
        {{
          'fgbcl.registration.payment.form.homenumber.error.empty' | translate : { fgbdefault: 'Phone Number cannot be empty.' }
        }}
      </div>
    </div>
  </form>
</div>
