import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company, CompanyOfferModel, CompanyOfferQuery, CompanyQuery } from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss'],
})
export class OffersPageComponent implements OnInit {
  featuredOffers$: Observable<CompanyOfferModel[]>;
  featuredPartners$: Observable<Company[]>;

  customOptions: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 2 },
      740: { items: 3 },
    },
  };

  customOptions2: OwlOptions = {
    autoWidth: true,
    margin: 36,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      540: { items: 4 },
      740: { items: 6 },
    },
  }; 

  constructor(private companyQuery: CompanyQuery, private companyOfferQuery: CompanyOfferQuery, private router: Router) {}

  ngOnInit() {
    this.featuredPartners$ = this.companyQuery.selectFavouriteCompanies();
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }

  navigateToFirstOfferFromAffiliate(affiliate: Company) {
    this.companyOfferQuery
      .selectCompanyOffers(affiliate.CompanyId)
      .pipe(
        take(1),
        tap((offer) => {
          if (offer[0]) {
            this.router.navigateByUrl(`offers/details/${offer[0].OfferId}`);
          }
        })
      )
      .subscribe();
  }
}
