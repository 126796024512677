import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  BalanceQuery,
  Card,
  CardQuery,
  CardToken,
  CardTokenQuery,
  config,
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
} from '@fgb/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit, OnDestroy {
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  ecash$: Observable<number | undefined>;
  pointsBalance$: Observable<number | undefined>;
  cardToken$: Observable<CardToken>;
  populations = Populations;

  constructor(
    private authService: AuthService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private balanceQuery: BalanceQuery,
    private cardTokenQuery: CardTokenQuery
  ) {}
  private routerSubscription: Subscription;
  public isCollapsed = true;

  ngOnInit() {
    this.initMemberAccounts();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => (this.isCollapsed = true))
      )
      .subscribe();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.cardToken$ = this.cardTokenQuery.selectDefaultCard();
  }

  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.cards$ = this.cardQuery.selectCards();
    this.selectedMember = this.memberQuery.getUserContext().memberId;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  switchToGuardian() {
    firstValueFrom(this.authService.switchToGuardian()).then(() => {
      this.router.navigate(['/']).then(() => {
        location.reload();
      });
    });
  }

  logout() {
    firstValueFrom(this.authService.logout()).then(() => {
      this.router.navigate(['/login']);
      // Remove the class so it doesn't inherit the theme styles when logging out
      document.body.className = '';
    });
  }

  loginWithCard(memberId: string) {
    firstValueFrom(this.authService.switchUser(memberId));
  }
}
