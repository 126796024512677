<div class="survey-answer">
  <div
    class="survey-answer-container py-3"
    [ngClass]="{
      'text-answer': survey.AnswerDisplayType == answerDisplayType.Text,
      'mx-lg-5': survey.SurveyType === surveyType.Poll,
      poll: survey.SurveyType === surveyType.Poll,
      quiz: survey.SurveyType === surveyType.Trivia,
      predictor: survey.SurveyType === surveyType.Predictor
    }"
  >
    <div class="p-0 {{ margins }} {{ colDisplayType }}" *ngFor="let answer of survey.SurveyAnswers">
      <div
        class="survey-answer-item cursor-pointer"
        (click)="selectAnswer(answer.SurveyAnswerId)"
        [ngClass]="{
          selected: selectedAnswers.includes(answer.SurveyAnswerId),
          'w-100': survey.SurveyType === surveyType.Predictor,
          'w-90': survey.SurveyType === surveyType.Poll,
          portrait: this.imgDisplayType === 'Portrait' && survey.AnswerDisplayType != answerDisplayType.Text
        }"
      >
        <ng-container [ngSwitch]="survey.AnswerDisplayType">
          <!-- Text with Image -->
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <div *ngIf="this.imgDisplayType === 'Portrait'">
              <div class="image-container">
                <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              </div>
              <div class="answer-text text-center">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
            <div *ngIf="this.imgDisplayType === 'Landscape'" class="d-flex flex-row">
              <img class="image-container icon" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              <div class="answer-text-landscape text-center answer-text">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
          </ng-container>
          <!-- Image Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Image">
            <div *ngIf="this.imgDisplayType === 'Portrait'">
              <div class="image-container">
                <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              </div>
            </div>
            <div *ngIf="this.imgDisplayType === 'Landscape'" class="d-flex flex-row">
              <img class="image-container icon" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
            </div>
          </ng-container>
          <!-- Text Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="answer-text px-3">
              <span>{{ answer.Title }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
