import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-badges-page',
  templateUrl: './badges-page.component.html',
  styleUrls: ['./badges-page.component.scss'],
})
export class BadgesPageComponent implements OnInit {
  populations = Populations;
  screenType = ScreenType;

  constructor() {}

  ngOnInit(): void {}
}
