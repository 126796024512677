import { Component, OnInit } from '@angular/core';
import { MemberService, Representative } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-my-representative',
  templateUrl: './my-representative.component.html',
  styleUrl: './my-representative.component.scss',
})
export class RepresentativeComponent implements OnInit {
  representative$: Observable<Representative>;
  constructor(private memberService: MemberService) {}

  ngOnInit(): void {
    this.representative$ = this.memberService.getRepresentative();
  }
}
