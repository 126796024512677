<div class="full-page bg-black panel w-100">
  <div class="overflow-hidden p-0">
    <div class="user-login-panel d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="login-banner mx-auto mb-4">
          <img src="assets/images/login-registration/ducks.svg" alt="miami-heats-logo" width="300" height="auto" />
        </div>
        <div class="col-12 user-login-panel-content bg-white mx-auto rounded-4 text-start">
          <h5 class="font-acumin-bold text-uppercase">{{ 'login.page.title' | translate }}</h5>
          <div
            class="text-black panel-text mlb-text font-size-14 font-default"
            [innerHTML]="'login.page.description' | translate | markdownTranslate"
          ></div>
          <fgb-tm-user-login></fgb-tm-user-login>
          <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
            <hr class="me-4 text-disabled" />
            <div class="font-size-14 font-default">Or</div>
            <hr class="ms-4 text-disabled" />
          </div>
          <p class="font-size-14 font-default">
            {{
              'login.page.instruction'
                | translate : { fgbdefault: 'Click “Create Account” below to register and access your rewards.' }
            }}
          </p>

          <div class="mt-lg-3">
            <a [routerLink]="['/register/new-member']">
              <div class="btn btn-primary create-account-button d-flex align-items-center justify-content-center w-100">
                <span class="fw-bold text-white font-size-14 font-sans-bold">
                  {{ 'login.page.create.account' | translate }}
                </span>
              </div>
            </a>
          </div>

          <div class="form-group mt-4 mt-lg-5 mb-lg-2">
            <a [routerLink]="['/register/partner']">
              <button class="btn btn-quaternary partner-btn d-flex align-items-center justify-content-center w-100">
                <span class="fw-bold text-white font-size-14 font-sans-bold">
                  {{ 'login.page.partner.invitation.btn' | translate }}
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
