import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MembershipOption } from '@fgb/core';

@Component({
  selector: 'fgb-membership-options-list',
  templateUrl: './membership-options-list.component.html',
  styleUrl: './membership-options-list.component.scss'
})
export class MembershipOptionsListComponent {
  @Input() membershipOptions: MembershipOption[];
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  

}
