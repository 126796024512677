import { Component, OnInit, Input, EnvironmentInjector, runInInjectionContext } from '@angular/core';
import { ChildViewModel, MembershipOptionQuery, MembershipOption } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { ChildLoginAction } from 'src/app/modules/guardian/components/child-panel/child-actions/child-login-action';
import { ChildAction } from './child-actions/child-action';
import { ChildUpgradeAction } from 'src/app/modules/guardian/components/child-panel/child-actions/child-upgrade-action';


@Component({
  selector: 'fgb-child-panel',
  templateUrl: 'child-panel.component.html',
  styleUrls: ['child-panel.component.scss'],
})
export class ChildPanelComponent implements OnInit {
  membershipOption: MembershipOption[];
  screenType = ScreenType;

  constructor(
    private membershipOptionQuery: MembershipOptionQuery,
    private injector: EnvironmentInjector
  ) {}

  @Input() child: ChildViewModel;
  childActions: ChildAction[] = [];

  ngOnInit() {
    this.membershipOptionQuery.selectAll().subscribe((options) => {
      this.membershipOption = options as MembershipOption[];
    });
    this._initializeActions();
  }

  // Initialize dynamic actions for the child panel
  private _initializeActions() {
    this.childActions = [
      // Create new instances of the dynamic action classes
      new ChildLoginAction(this.child),
      new ChildUpgradeAction(this.child)
    ];
  }

  // Invoke the dynamic action classes, with the new angular version, we can now use the injector to run the action using the runInInjectionContext method everywhere,
  // might not be practical for this component but for more complex components like marketplace having different buttons, this example can be useful.
  // In good programming practice, per component needs less than 200 lines of code. this can reduce the complexity of the component and lines of code.
  invoke(action: ChildAction) {
    runInInjectionContext(this.injector, () => action.invoke());
  }

}
