<div *ngIf="currentSubscribedProduct && currentSubscribedProduct.ProductType == 1">
  <div class="mt-3 container">
    <div class="row shadow rounded sub-card">
      <div
        class="col-3 text-center rounded-start"
        [ngClass]="{
          'bg-primary': currentSubscribedProduct.ProductType == 1
        }"
      >
        <img alt="" class="product-image" src="{{ currentSubscribedProduct.PrimaryImageURL | contentImage: '6' }}" />
      </div>
      <div class="col-9 mt-2 d-flex flex-column justify-content-between">
        <div class="row">
          <div class="col-8">
            <div
              class="font-size-14 fw-bold"
              [ngClass]="{
                'text-primary': currentSubscribedProduct.ProductType == 1
              }"
            >
              {{ currentSubscribedProduct.DisplayName }}
            </div>
          </div>
          <div class="col-4 text-end" (click)="openProductDescription(productInfo)">
            <button class="btn p-0">
              <label class="accessibility-only">{{ 'subscription.open.description.screenreader' | translate }}</label>
              <img alt="" src="assets/images/icons/info-icon.svg" class="info-icon" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="font-size-12 subs-description text-info" [innerHTML]="currentSubscribedProduct.Description"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!--info of purchased product-->
            <div *ngIf="currentSubscribedProduct">
              <div class="row">
                <div class="col-lg-5 col-4">
                  <div class="font-size-12">
                    <p class="fw-bold mb-0">Expires:</p>
                    <p *ngIf="currentLcid !== '3084'" class="mb-0 pb-2 text-nowrap">
                      {{ currentSubscribedProduct.NextPaymentDueDate | date }}
                    </p>
                    <!-- <p *ngIf="currentLcid == '3084'" class="mb-0 pb-2 text-nowrap">
                      {{ currentSubscribedProduct.NextPaymentDueDate | translateDate:true }}
                    </p> -->
                  </div>
                </div>
                <div class="col-lg-7 col-8">
                  <button
                    *ngIf="currentSubscribedProduct.ProductType == 1 && currentSubscribedProduct && withinRenewal"
                    class="btn btn-black w-100 text-white font-size-14 fw-bold"
                    (click)="addRenewalProductToCart()"
                  >
                    <span>Renew</span>
                    <span> {{ renewalProduct.MerchantPrice / 100 | FortressCurrencyPipe }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Product info modal-->
<ng-template #productInfo let-modal>
  <div class="text-black bg-white">
    <div class="modal-header border-bottom-0 pb-0">
      <p class="font-size-14 fw-bold mx-auto">Subscription Details</p>
    </div>
    <hr class="mt-0 mx-5" />
    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <div
            class="text-center rounded info-image-bg"
            [ngClass]="{
              'bg-primary': currentSubscribedProduct.ProductType == 1
            }"
          >
            <img
              alt=""
              class="info-product-image my-2"
              src="{{ currentSubscribedProduct.PrimaryImageURL | contentImage: '6' }}"
            />
          </div>
        </div>
        <div class="col-10">
          <p>{{ currentSubscribedProduct.DisplayName }}</p>
          <p>
            <span class="fw-bold">Cost: </span>
            {{ currentSubscribedProduct.MerchantPrice / 100 | FortressCurrencyPipe }}
          </p>
          <p [innerHTML]="currentSubscribedProduct.Description"></p>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-primary close-info-btn fw-bold" (click)="modal.dismiss('close')">Close</button>
      </div>
    </div>
  </div>
</ng-template>
