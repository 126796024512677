<div class="partner-list">
  <div class="card rounded border card-primary my-2 bg-white">
    <div class="card-body pt-1 pb-2">
      <div class="row text-start pt-2">
        <div class="col-lg-3 d-lg-block d-none">
          <label class="fw-bold">{{ 'partner.list.fullname.label' | translate : { fgbdefault: 'Full Name' } }}</label>
        </div>
        <div class="col-8 d-lg-none d-block">
          <label class="fw-bold">{{
            'partner.list.peronal.details.label' | translate : { fgbdefault: 'Partner Details' }
          }}</label>
        </div>
        <div class="col-4 d-lg-block d-none">
          <label class="fw-bold">{{ 'partner.list.email.label' | translate : { fgbdefault: 'Email' } }}</label>
        </div>
        <div
          class="d-lg-block d-none"
          [ngClass]="{
            'ms-auto': !showDeleteButton,
            'col-1 me-3': !showDeleteButton,
            'col-3': showDeleteButton
          }"
        >
          <label class="fw-bold">{{ 'partner.list.status.label' | translate : { fgbdefault: 'Status' } }}</label>
        </div>
        <div class="col-4 d-lg-none d-block text-end" *ngIf="showDeleteButton">
          <label class="fw-bold me-2 me-lg-0">{{ 'partner.list.action.label' | translate : { fgbdefault: 'Action' } }}</label>
        </div>
        <div class="col-2 d-lg-block d-none" *ngIf="showDeleteButton">
          <label class="fw-bold">{{ 'partner.list.action.label' | translate : { fgbdefault: 'Action' } }}</label>
        </div>
      </div>
      <hr class="m-0 mt-3" />
      <ng-container *ngIf="{ partners: partners$ | async, pending: pendingPartners$ | async } as partner">
        @if( partner.partners.length > 0 || partner.pending.length > 0 ){
        <div
          *ngFor="let partner of partner.partners"
          class="row text-start align-items-lg-center mb-lg-0 mb-2 mt-3 mt-lg-0 details-table"
        >
          <div class="col-lg-3 col-10 partner-info">
            <div class="mb-2">{{ partner.FullName }}</div>
            <div class="d-flex d-lg-none flex-column">
              <div>{{ partner.Email }}</div>
              <div class="text-success fw-bold">{{ 'partner.list.partner.label' | translate : { fgbdefault: 'Partner' } }}</div>
            </div>
          </div>
          <div class="col-4 d-lg-block d-none partner-info">
            <div>{{ partner.Email }}</div>
          </div>
          <div
            class="d-lg-block d-none partner-info"
            [class.ms-auto]="!showDeleteButton"
            [ngClass]="{
              'ms-auto': !showDeleteButton,
              'col-1 me-3': !showDeleteButton,
              'col-3': showDeleteButton
            }"
          >
            <div class="text-success fw-bold">{{ 'partner.list.partner.label' | translate : { fgbdefault: 'Partner' } }}</div>
          </div>
          <div class="col-2 px-1" *ngIf="showDeleteButton">
            <button
              class="btn text-grey delete-btn"
              confirmModal
              [confirmContent]="confirmContent"
              [confirmContext]="partner"
              (confirm)="delete(partner.RelationshipId)"
              aria-label="Delete partner"
            >
              <span class="icon material-icons"> delete_forever </span>
            </button>
          </div>
        </div>

        <div *ngFor="let partner of partner.pending" class="row text-start align-items-lg-center mb-lg-0 mb-2 mt-3 mt-lg-0">
          <div class="col-lg-3 col-10 partner-info">
            <div class="partner-fname">{{ partner.FullName }}</div>
            <div class="d-flex d-lg-none flex-column">
              <div class="partner-email">{{ partner.Email }}</div>
              <div class="status-pending fw-bold">{{ 'partner.list.pending.label' | translate : { fgbdefault: 'Pending' } }}</div>
            </div>
          </div>
          <div class="col-4 d-lg-block d-none partner-info">
            <div class="partner-email">{{ partner.Email }}</div>
          </div>
          <div class="col-3 d-lg-block d-none partner-info">
            <div class="status-pending fw-bold">{{ 'partner.list.pending.label' | translate : { fgbdefault: 'Pending' } }}</div>
          </div>
          <div class="col-2 btn-wrapper">
            <button
              class="btn text-grey delete-btn"
              confirmModal
              [confirmContent]="confirmContent"
              [confirmContext]="partner"
              (confirm)="deletePending(partner.TicketKey)"
              aria-label="Delete partner"
            >
              <span class="icon material-icons"> delete_forever </span>
            </button>
          </div>
        </div>
        }@else {
        <div class="no-result">
          {{ noResultPlaceholder || 'partner.list.no.result.placeholder' }}
        </div>
        }
      </ng-container>
      <ng-template #confirmContent let-partner>
        <div class="delete-partner-modal">
          <div class="modal-header pb-3">
            <h5>{{ 'partner.list.modal.delete.partner.title' | translate }}</h5>
          </div>
          <div class="modal-body">
            <p>{{ 'partner.list.partner.delete.prompt' | translate }}</p>
            <p class="mb-0">
              <b>{{ partner.FullName }}</b> ({{ partner.Email }})
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
