import { Component, OnInit } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, firstValueFrom, map } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UpgradeChildrenComponent } from '../upgrade-children/upgrade-children.component';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-child-list',
  templateUrl: 'child-list.component.html',
  styleUrls: ['child-list.component.scss'],
})
export class ChildListComponent implements OnInit {
  loaded$: Observable<boolean>;
  children$: Observable<any>;
  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    pullDrag: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1.2, autoWidth: true },
      400: { items: 1.2, autoWidth: true },
      740: { items: 3 },
    },
  };

  constructor(private childQuery: ChildQuery, private childService: ChildService, private modalService: NgbModal) {}

  ngOnInit() {
    firstValueFrom(this.childService.fetchChildren());
    firstValueFrom(this.childService.fetchChildCountLimit());

    this.children$ = this.childQuery.getChildrenInfo().pipe(
      map((children) => {
        return {
          data: children.data,
          free: children.free,
          empty: children.empty,
          limit: children.limit,
          upgradable: children.data.filter(child => child.AccountPaymentState === 0),
          nonUpgradable: children.data.filter(child => child.AccountPaymentState === 1),
        }
      })
    );
    this.loaded$ = this.childQuery.isLoaded$;
  }

  upgradeChildren(freeChildren: ChildViewModel[]) {
    const modal = this.modalService.open(UpgradeChildrenComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'upgrade-children-modal',
      size: 'lg',
    });
    modal.componentInstance.children = freeChildren;
  }
}
