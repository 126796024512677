<div class="banner d-lg-flex d-none">
  <h1>{{ 'featured.rewards.page.title' | translate }}</h1>
</div>

<div class="container my-3" *ifScreenSize="screenType.Mobile">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <!-- <fgbcl-mixed-loyalty-list [onlyFavourites]="true"></fgbcl-mixed-loyalty-list> -->
</div>
