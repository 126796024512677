import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.scss'],
})
export class MarketplacePageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService) {}

  ngOnInit() {
    firstValueFrom(this.marketplaceService.fetchMarketplaceItems());
  }
}
