import { Component } from '@angular/core';

@Component({
  selector: 'fgb-portal-product-success-page',
  templateUrl: './portal-product-success-page.component.html',
  styleUrls: ['./portal-product-success-page.component.scss'],
})
export class PortalProductSuccessPageComponent {

  constructor() {}


}
