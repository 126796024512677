import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MobilePassComponent } from '../passes/components/mobile-pass/mobile-pass.component';
import { MobilePassModalComponent } from '../passes/components/mobile-pass-modal/mobile-pass-modal.component';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';

@NgModule({
  declarations: [MobilePassComponent, MobilePassModalComponent],
  exports: [MobilePassComponent],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    QRCodeModule,
    RouterModule,
    InlineSVGModule.forRoot(),
    FGBSharedModule
  ],
})
export class PassesModule {}
