<div class="" *ngIf="barcode$ | async as barcode">
  <div class="p-0 d-flex flex-wrap flex-column justify-content-around align-items-center rounded border bg-white">
    <div class="font-size-10 font-italic mt-2 barcode-message">{{ 'barcode.no.access' | translate }}</div>
    <ng-container *ngIf="card$ | async as card">
      <div class="qr">
        <ng-container *ngIf="barcode.Barcode; else loadingRef">
          <qrcode [qrdata]="barcode.Barcode" [width]="156" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
        </ng-container>
      </div>

      <div class="d-flex justify-content-center align-items-center" *ngIf="card$ | async as card">
        <!--Name and ID-->
        <div class="text-center">
          <h5 class="mb-0 text-uppercase card-name w-100 text-secondary" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </h5>
          <ng-container *inPopulation="[populations.Orange]">
            <div class="product-code mb-2">
              <div>{{ 'mobile.barcode.orange' | translate : { fgbdefault: 'Season' } }} -</div>
              <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
            </div>
          </ng-container>
          <ng-container *inPopulation="[populations.Default]">
            <div class="product-code mb-2">
              <div>{{ 'mobile.barcode.fan' | translate : { fgbdefault: 'Ducks Fan' } }} -</div>
              <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
            </div>
          </ng-container>
          <ng-container *inPopulation="[populations.Partial]">
            <div class="product-code 2 mb-2">
              <div>{{ 'mobile.barcode.plan' | translate : { fgbdefault: 'Plan Holder' } }} -</div>
              <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
            </div>
          </ng-container>
          <ng-container *inPopulation="[populations.Partner]">
            <div class="product-code mb-2">
              <div>{{ 'mobile.barcode.partner' | translate : { fgbdefault: 'Partner' } }} -</div>
              <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
            </div>
          </ng-container>
          <ng-container *inPopulation="[populations.Kids]">
            <div class="product-code mb-2">
              <div>{{ 'mobile.barcode.kids' | translate : { fgbdefault: 'Wild Wingers' } }} -</div>
              <div class="card-member-id ps-1">{{ card.ExternalRefNumber }}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <!--Wallet Button-->
      <div class="button-wrapper pb-2" *ngIf="showWalletButton">
        <button class="btn wallet-btn" [routerLink]="['/rewards/wallet']">
          <i class="icon material-icons">account_balance_wallet</i>
          {{ 'barcode.wallet.button' | translate : { fgbdefault: 'Wallet' } }}
        </button>
      </div>

      <ng-container *inPopulation="[populations.Orange, populations.Partner]">
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment.toggle'">
          <div class="cash-back-toggle-container px-5 py-2">
            <fgbcl-cash-back-toggle></fgbcl-cash-back-toggle>
          </div>
        </ng-container>
      </ng-container>

      <!-- Barcode Details -->
      <ng-container *notInPopulation="[populations.Default, populations.Kids]">
        <div class="w-100 barcode-content-container">
          <div class="barcode-content">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-end w-100">
                <ng-container *notInPopulation="[populations.Partial]">
                  <div class="card-account-no text-start pe-3" *ngIf="pointsBalance$">
                    <div class="value">{{ pointsBalance$ | async | number }}</div>
                    <label class="mb-0">{{ 'member.card.points.balance' | translate }}</label>
                  </div>
                  <div class="card-id ecash-balance text-start ps-3 ms-3 pe-3" *ngIf="ecash$">
                    <div class="value">{{ ecash$ | async | FortressCurrencyPipe }}</div>
                    <label class="mb-0">{{ 'member.card.ecash.balance' | translate }}</label>
                  </div>
                </ng-container>
                <!--Discount-->
                <div class="text-start ps-4 ms-2 pe-3">
                  <div class="value">{{ card.DiscountLevel ?? 0 }}%</div>
                  <label class="mb-0 discount-title">{{
                    'member.card.discount.label' | translate : { fgbdefault: 'F&B' }
                  }}</label>
                </div>
                <!--Retail Discount-->
                <div class="text-start ps-3 pe-3 ms-auto">
                  <div class="value">{{ card.RetailDiscountLevel ?? 0 }}%</div>
                  <label class="mb-0 discount-title">{{
                    'member.card.retail.discount.label' | translate : { fgbdefault: 'Merch' }
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Loading template -->
<ng-template #loadingRef>
  <div class="loading-container">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
