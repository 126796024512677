<div class="banner" role="banner">
  <h1>{{ 'earn.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="col-12">
    <h5 class="sub-heading text-primary fw-bold pt-2 pt-lg-4 pb-0">{{ 'earn.info.title' | translate }}</h5>
    <div class="">
      {{ 'earn.info.description' | translate }}
    </div>
  </div>
</div>

<div class="container">
  <fgbcl-earn-points [svgColor]="'primary'"></fgbcl-earn-points>
</div>
