import { Component, OnInit, Input, Output, EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { WorksheetService } from '@fgb/core';
declare var YT: any;
declare var gtag: any;

@Injectable({ providedIn: 'root' })
export class YoutubeService {
  static behaviourSubject: BehaviorSubject<any>;

  constructor() {}

  addVideo(): Observable<any> {
    if (YoutubeService.behaviourSubject == null) {
      YoutubeService.behaviourSubject = new BehaviorSubject<any>('initializing');

      LoadScripts([{ src: 'https://www.youtube.com/iframe_api' }] as DynamicScript[])
        .then(() => {
          if (!(window as any)['onYouTubeIframeAPIReady']) {
            (window as any)['onYouTubeIframeAPIReady'] = (e: any) => {
              let status = YoutubeService.behaviourSubject.value;
              if (status != 'loaded') {
                YoutubeService.behaviourSubject.next('loaded');
              }
            };
          }
          else {
            let status = YoutubeService.behaviourSubject.value;
            if (status != 'loaded') {
              YoutubeService.behaviourSubject.next('loaded');
            }
          }
        });

    }

    return YoutubeService.behaviourSubject.asObservable();
  }
}

@Component({
  selector: 'fgb-youtube-player',
  templateUrl: './fgb-youtube-player.component.html',
  styleUrls: ['./fgb-youtube-player.component.scss'],
})
export class FgbYoutubePlayerComponent implements OnInit, OnDestroy {
  @Input() streamKey: string;
  @Output() onStateChange = new EventEmitter();

  private subscription: Subscription;
  public static analyticsInterval = 0;

  constructor(private youtubeService: YoutubeService, 
              private worksheetService: WorksheetService) {}

  ngOnInit() {
    
    this.subscription = this.youtubeService
      .addVideo()
      .pipe(delay(100))
      .subscribe((youtubeStatus) => {
        if (youtubeStatus == 'loaded') {
          let onStateChangeFunction = this.onStateChange;
          let streamKeyValue = this.streamKey;
          let workSheetItemService = this.worksheetService;
          new YT.Player('ytplayer_' + this.streamKey, {
            width: '100%',
            height: '100%',
            videoId: this.streamKey,
            playerVars: {
              rel: 0,
              showinfo: 0
            },
            events: {
              'onReady': this.onPlayerReady,
              onStateChange: function (event: any) {
                onStateChangeFunction.emit(event);
                
                if( event.target.playerInfo.videoData.isLive ){
                  if (event.data == 1) {
                    // start
                    workSheetItemService.logViewStreamStart(streamKeyValue);
                    FgbYoutubePlayerComponent.analyticsInterval = window.setInterval(() => {
                      gtag('event', 'streaming', {
                        streamKey: streamKeyValue,
                        event_category: 'watching_stream',
                        event_label: streamKeyValue,
                      });
                      workSheetItemService.logStreamWatching(streamKeyValue);
                    }, 30000);

                    gtag('event', 'streaming', {
                      streamKey: streamKeyValue,
                      event_category: 'start_stream',
                      event_label: streamKeyValue,
                    });
                  } else if (event.data == 2) {
                    // pause
                    gtag('event', 'streaming', {
                      streamKey: streamKeyValue,
                      event_category: 'pause_stream',
                      event_label: streamKeyValue,
                    });
                    window.clearInterval(FgbYoutubePlayerComponent.analyticsInterval);
                  }
                }
              },
            },
          });
        }
      });
   
  }

  ngOnDestroy() {
    window.clearInterval(FgbYoutubePlayerComponent.analyticsInterval);
    this.subscription.unsubscribe();
  }

  onPlayerReady(event: any) {
    let playButton = document.getElementById("video-play-button");
    
    if( playButton ){
      playButton.addEventListener('click', () => {
        if (event.target.getPlayerState() == YT.PlayerState.PLAYING) {
          event.target.pauseVideo();
      } else {
          playButton!.style.display = "none";
          event.target.playVideo();
      }
      });
    }

  }
}
