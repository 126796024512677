<div
  class="survey-container predictor"
  [ngClass]="{
    answered: survey.HasBeenAnsweredByUser
  }"
>
  <div class="survey-header mb-1 col-lg-6 p-0 rounded overflow-hidden border">
    <div class="image-container" [class.no-image]="!survey.ImageURL">
      <ng-container *ngIf="survey.ImageURL; else noImage">
        <img src="{{ survey.ImageURL | contentImage : '23' }}" alt="{{ survey.ImageURL }}" />
      </ng-container>
      <ng-template #noImage>
        <div class="d-flex flex-column align-items-center">
          <h3 class="text-white predictor-title text-uppercase">
            {{ 'survey.predictor.title' | translate : { fgbdefault: 'Predictor' } }}
          </h3>
          <p class="font-size-14 text-white">
            {{ 'survey.predictor.instructions' | translate : { fgbdefault: 'Pick Your Answer' } }}
          </p>
        </div>
      </ng-template>
    </div>
    <div class="survey-info d-flex flex-column align-content-between">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <div class="gIcon-predictor material-icons" aria-hidden="true">flaky</div>
        </div>
        <div class="text-end d-flex align-items-center">
          <ng-container *ngIf="status !== displayStatus.ComingSoon && !survey.HasBeenAnsweredByUser">
            <i class="material-icons me-1">schedule</i>
            <fgbcl-countdown [endDateTime]="survey.EndDate" [isUTC]="true" class="font-size-14 fw-bold"></fgbcl-countdown>
          </ng-container>
        </div>
      </div>
      <div class="survey-title mb-3">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
      <div class="d-flex align-items-end mt-4 h-100">
        <ng-container *ngIf="status != displayStatus.ComingSoon">
          <button
            *ngIf="!survey.HasBeenAnsweredByUser"
            (click)="submitAnswer()"
            class="btn btn-submit col-6 fw-bold mx-auto"
            [disabled]="itemAnswer?.selectedAnswers?.length === 0"
          >
            {{ 'survey.predictor.submit.button' | translate : { fgbdefault: 'Submit' } }}
          </button>
        </ng-container>
        <div
          class="d-flex flex-row w-100 justify-content-center my-1"
          *ngIf="survey.HasBeenAnsweredByUser && survey.ProcessStatus !== processStatus.Processed"
        >
          <div class="text-uppercase text-primary fw-bold px-2 pt-1 entered-text mb-2">
            {{ 'survey.item.predictor.entered' | translate : { fgbdefault: 'Entered' } }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6">
    <ng-container *ngIf="status != displayStatus.ComingSoon; else comingSoon">
      <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
        <fgb-survey-item-answer-completed-predictor [survey]="survey"></fgb-survey-item-answer-completed-predictor>
      </ng-container>
      <ng-template #answer>
        <div class="h-100 w-100 d-table ps-lg-4">
          <div class="d-table-cell align-middle">
            <fgb-survey-item-answer #itemAnswer [survey]="survey" (predictorAnswers)="hasUserAnswered()"></fgb-survey-item-answer>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #comingSoon>
  <div class="coming-soon h-100 d-flex align-items-center justify-content-center">
    <p class="text-center fw-bold text-primary m-0">
      {{ 'survey.item.quiz.coming.soon.text' | translate : { fgbdefault: 'Coming soon' } }}
    </p>
  </div>
</ng-template>
